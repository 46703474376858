import React, { useEffect, useState, useContext } from "react";
import ClientDashboardAside from "../../ClientDashboardAside";
import { HeaderDashboard } from "../../Header";
import { Backdrop, CircularProgress } from "@mui/material";
import Pagination from "react-bootstrap/Pagination";

import Rating from "@mui/material/Rating";
import { useCookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../BaseUrl";
import Global from "../../../context/Global";

const AllProfessionalList = () => {
  const [purchaseDesigns, setPurchaseDesigns] = useState();
  const [purchaseDesignsPagination, setPurchaseDesignsPagination] = useState({
    page: 1,
    page_size: 50,
  });
  const [isRender, setIsReander] = useState(false);
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const contextData = useContext(Global);
  // const [browseProfessinalPageId, setBrowseProfessionalPageId] = useState({
  //   page: 1,
  //   page_size: 10,
  // });
  const [defaultProfessionalProfile, setDefaultProfessionalProfile] = useState(
    []
  );

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    if (cookies?.user_data) {
      if (cookies?.user_data?.category_selected) {
        if (cookies?.user_data?.role === "client") {
        } else {
          navigate("/professionaldashboard");
        }
      } else {
        if (cookies?.user_data?.role === "client") {
          navigate("/client-architechture");
        } else {
          navigate("/categoryArchitecture");
        }
      }
    } else {
      navigate("/select-sign-in");
    }
  }, []);

  const purchaseDesignsArray = [];
  for (
    let i = 0;
    i < purchaseDesigns?.total_data / purchaseDesignsPagination?.page_size;
    i++
  ) {
    purchaseDesignsArray?.push(i + 1);
  }

  useEffect(() => {
    if (cookies?.user_data && cookies?.user_data.role === "client") {
      // !defaultProfessionalProfile?.length &&
      axios
        .post(`${BaseUrl}/client/all_profesional_list`, {
          client_id: cookies?.user_data?.user_id,
          user_token: cookies?.user_data?.user_token,
          role: cookies?.user_data?.role,
          ...purchaseDesignsPagination,
        })
        .then((res) => {
          if (res?.data?.status === "Success") {
            setPurchaseDesigns(res?.data?.data);
            setIsReander(true);
            setDefaultProfessionalProfile(res?.data?.data);
          }
        });
    }
  }, [purchaseDesignsPagination, contextData?.currentTabClientDashboard]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [purchaseDesignsPagination]);
  const paginationArray = [];
  // for (
  //   let i = 0;
  //   i <
  //   defaultProfessionalProfile?.total_data / browseProfessinalPageId?.page_size;
  //   i++
  // ) {
  //   paginationArray.push(i + 1);
  // }
  return (
    <div className="dashboard">
      <div className="container-fluid h-100">
        <div className="row h-100 dashboard-theme-color">
          <div className="col-xxl-2 col-md-3 col-lg-3 px-0 dashboard-theme-color">
            <ClientDashboardAside />
          </div>
          <div className="col-xxl-10 col-md-9 col-lg-9 custom-border-radius-one dashboard-theme-skyblue px-0 dashboard-right-section">
            <HeaderDashboard />
            {!isRender ? (
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={!isRender}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            ) : (
              <main className="dashboard-main">
                <div
                  id="dashboard-menu-bar"
                  className="container-fluid  px-md-4 px-3"
                >
                  <div className="find-visualizer all-professionals">
                    <h3 className="m-0 mt-2">
                      <span
                        className="text-decoration-none text-dark m-0 h2 pointer"
                        onClick={() => navigate(-1)}
                      >
                        <i
                          className="fa-solid fa-arrow-left-long pe-3"
                          style={{ color: "#01a78a" }}
                        ></i>
                      </span>
                      All Professionals
                    </h3>
                    <div className="row">
                      {defaultProfessionalProfile &&
                        defaultProfessionalProfile?.final_data?.map(
                          (res, index) => {
                            return (
                              <div
                                className="col-xxl-4 col-lg-6  my-3"
                                key={index}
                              >
                                <div className="item">
                                  <div className="henry-section">
                                    <div className="henry-img">
                                      <img src={res?.avatar} alt={res?.name} />
                                      {res?.is_online ? (
                                        <div className="online"></div>
                                      ) : (
                                        <div className="offline"></div>
                                      )}
                                    </div>
                                    <div className="henry-text">
                                      <h6
                                        style={{ textTransform: "capitalize" }}
                                      >
                                        {res?.name}
                                      </h6>
                                      <span>
                                        <img
                                          src="./static/images/project.png"
                                          className="object-fit"
                                          alt={res?.projects}
                                        />
                                        {res?.projects}+ Projects Done
                                      </span>
                                      <span style={{ fontWeight: "600" }}>
                                        Minimum Rate Per sq/mtr :
                                        <b
                                          style={{
                                            fontSize: "18px",
                                            marginLeft: "4px",
                                          }}
                                        >
                                          {" "}
                                          $ {res?.price_range}
                                        </b>
                                      </span>
                                    </div>
                                  </div>
                                  <div className="add-hire">
                                    <Rating
                                      name="read-only"
                                      value={parseInt(res?.ratings)}
                                      readOnly
                                      precision={0.5}
                                    />
                                    <div className="add-btn">
                                      <button
                                        onClick={() => {
                                          navigate(
                                            `/professionalprofile/${res?.professional_id}`
                                          );
                                          // contextData?.dispatch({
                                          //   type: "PROFESSIONAL_USER_PROFILE_DATA",
                                          //   value: null,
                                          // });
                                        }}
                                      >
                                        View Profile
                                        <img
                                          src="https://img.icons8.com/fluency-systems-regular/20/ffffff/long-arrow-right.png"
                                          alt="add/hire"
                                        />
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                </div>
              </main>
            )}
            {purchaseDesigns?.total_data >= 50 ? (
              <Pagination className="ps-5 paginationBoxProfessionalDashboard">
                <Pagination.First
                  onClick={() => {
                    setPurchaseDesignsPagination({
                      page: 1,
                      page_size: 50,
                    });
                  }}
                />
                <Pagination.Prev
                  onClick={() => {
                    setPurchaseDesignsPagination((prev) => ({
                      ...prev,
                      page:
                        purchaseDesignsPagination?.page !== 1
                          ? purchaseDesignsPagination?.page - 1
                          : 1,
                    }));
                  }}
                />
                {purchaseDesignsArray?.map((res, key) => (
                  <Pagination.Item
                    key={key}
                    active={purchaseDesignsPagination?.page === res}
                    onClick={() => {
                      setPurchaseDesignsPagination((prev) => ({
                        ...prev,
                        page: res,
                      }));
                    }}
                  >
                    {res}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => {
                    setPurchaseDesignsPagination((prev) => ({
                      ...prev,
                      page:
                        purchaseDesignsArray?.length !==
                        purchaseDesignsPagination?.page
                          ? purchaseDesignsPagination?.page + 1
                          : purchaseDesignsPagination?.page,
                    }));
                  }}
                />
                <Pagination.Last
                  onClick={() => {
                    setPurchaseDesignsPagination((prev) => ({
                      ...prev,
                      page: purchaseDesignsArray?.length,
                    }));
                  }}
                />
              </Pagination>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AllProfessionalList;
