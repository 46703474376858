import React, { useEffect, useState } from "react";
import ClientDashboardAside from "../../ClientDashboardAside";
import { HeaderDashboard } from "../../Header";
import { Backdrop, CircularProgress, Container } from "@mui/material";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { NavLink, useNavigate, useParams } from "react-router-dom";
import axios from "axios";
import { BaseUrl } from "../../../BaseUrl";
import Dashboardside from "../../ProfessionalDashboardside";
import { useCookies } from "react-cookie";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
const ClientContracts = () => {
  const [isRender, setIsReander] = useState(true);
  const [contratData, setContractData] = useState([]);
  const [cookies, setCookie] = useCookies();
  const navigate = useNavigate();

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  });

  const { id } = useParams();
  useEffect(() => {
    axios
      .post(`${BaseUrl}/client/contract_details/`, {
        user_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
        contract_id: id,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          setContractData(res?.data?.data);
        }
      })
      .catch((error) => {});
  }, []);

  const handleClientAcceptation = () => {
    if (cookies?.user_data?.role === "client") {
      navigate("/project-details", {
        state: {
          client_id: cookies?.user_data?.user_id,
          projectData: {
            project_id: contratData?.project_id,
            professional_id: contratData?.professional_id,
            professional_image: contratData?.professional_image,
          },
          isFromClientTab: true,
        },
      });
    } else {
      navigate("/project-details", {
        state: {
          client_id: contratData?.client_id,
          project_id: contratData?.project_id,
          isFromProfessionalTab: true,
        },
      });
    }
  };
  //Handle end contract
  const [showEndContractModal, setShowEndContractModal] = useState(false);
  const [endReason, setEndReason] = useState("");
  const [endReasonerr, setEndReasonerr] = useState(false);
  const handleEndContractClose = () => {
    setShowEndContractModal(false);
    setEndReasonerr(false);
  };
  const handleEndContractShow = () => setShowEndContractModal(true);
  const handleEndContract = () => {
    if (endReason) {
      setIsReander(false);
      axios
        .post(`${BaseUrl}/client/project/end-contract/`, {
          project_id: contratData?.project_id,
          client_id: cookies?.user_data?.user_id,
          reason: endReason,
          client_token: cookies?.user_data?.user_token,
          role: cookies?.user_data?.role,
          contract_id: contratData?.contract_id,
        })
        .then((res) => {
          navigate("/contract");
          setIsReander(true);
          handleEndContractClose();
          setEndReasonerr(false);
        })
        .catch((err) => {});
    } else {
      setEndReasonerr(true);
      setIsReander(true);
    }
  };

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid h-100">
          <div className="row h-100 dashboard-theme-color">
            <div className="col-xxl-2 col-md-3 col-lg-3 px-0 dashboard-theme-color">
              {cookies?.user_data?.role === "client" ? (
                <ClientDashboardAside />
              ) : (
                <Dashboardside />
              )}
            </div>
            <div className="col-xxl-10 col-md-9 col-lg-9 custom-border-radius-one dashboard-theme-skyblue px-0 dashboard-right-section">
              <HeaderDashboard />
              {!isRender ? (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={!isRender}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <section className="py-5 pt-0 pe-4 pe-lg-3 pe-xl-5 ps-lg-3 ps-xl-5 ps-2">
                  <div className="contract-wrapper ">
                    <div className="contracts-heading">
                      <div
                        className="me-auto"
                        style={{
                          fontSize: "25px",
                          color: "#01a78a",
                          cursor: "pointer",
                        }}
                      >
                        <i
                          style={{ fontSize: "30px" }}
                          className="fa-solid fa-arrow-left-long"
                          onClick={() => {
                            navigate(-1);
                          }}
                        ></i>
                      </div>
                      <h2 style={{ color: "#00A78B" }}>Contracts</h2>
                    </div>
                    <div className="container-fluid mt-4">
                      <div className="row ">
                        <div className="mileston-details col-lg-12 col-xxl-9 pe-lg-4 pe-xl-4 pe-xxl-5 pe-0">
                          <div className="row d-sm-flex d-block  justify-content-between">
                            <div className="col   border border-20 px-4 py-3">
                              <span className="fs-5">
                                Total Estimated Amount
                              </span>
                              <h3 className="fw-bold">
                                ${contratData?.agreed}
                              </h3>
                            </div>
                            <div className="col mx-xl-5 mx-lg-2 mx-md-1 mx-sm-1  border-20 border px-4 py-3 my-2 my-sm-0">
                              <span className="fs-5">
                                {" "}
                                {cookies?.user_data?.role === "client"
                                  ? "Paid Amount"
                                  : "Received Amount"}
                              </span>
                              <h3 className="fw-bold">
                                ${contratData?.received}
                              </h3>
                            </div>
                            <div className="col  border-20 border px-4 py-3">
                              <span className="fs-5">Remaining Amount</span>
                              <h3 className="fw-bold">
                                ${contratData?.remaining}
                              </h3>
                            </div>
                          </div>
                          <div className="row border-20 border my-4 py-4 px-3 align-middle ">
                            <div className="col-3 align-middle">
                              <h6>Reference Project File</h6>
                            </div>
                            <div className="col-md-4">
                              <a
                                download
                                target="_blank"
                                href={contratData?.attachment}
                                style={{ color: "#00A78B", fontSize: "18px" }}
                              >
                                Download
                              </a>
                            </div>
                          </div>
                          <div className="row">
                            <h3 className="my-4 fw-bold">Milestone</h3>
                            <div className="container">
                              {contratData?.milestones_detail &&
                                contratData?.milestones_detail?.map(
                                  (item, index) => {
                                    return (
                                      <div
                                        className=" row border-bottom d-flex justify-content-between align-item-center mb-4 py-2 "
                                        key={index}
                                      >
                                        <span className=" col-sm-3 col-6">
                                          {item?.name}
                                        </span>
                                        <div className=" col-sm-2 col-6">
                                          <span style={{ color: "#00A78B" }}>
                                            {item?.payment_status ===
                                            "completed" ? (
                                              <CheckCircleOutlineIcon />
                                            ) : null}
                                            ${item?.price}
                                          </span>
                                        </div>
                                        <div className=" col-sm-2 col-6">
                                          {item?.milestone_date}
                                        </div>

                                        <div className=" col-sm-3 col-6">
                                          {item?.status === "approved" ? (
                                            <span>
                                              Approved {item?.approve_time} ago
                                            </span>
                                          ) : null}
                                        </div>
                                        <div className=" col-sm-2 col-6">
                                          <span>
                                            {item?.status === "approved" ? (
                                              <button
                                                className="btn success text-white"
                                                style={{
                                                  backgroundColor: "#00A78B",
                                                }}
                                                onClick={() => {
                                                  handleClientAcceptation();
                                                }}
                                              >
                                                Review submissions
                                              </button>
                                            ) : (
                                              <button
                                                className="btn success text-white"
                                                style={{
                                                  backgroundColor: "#00A78B",
                                                }}
                                                onClick={() => {
                                                  handleClientAcceptation();
                                                }}
                                              >
                                                {cookies?.user_data?.role ===
                                                "client"
                                                  ? "View work"
                                                  : "Submit Work"}
                                              </button>
                                            )}
                                          </span>
                                        </div>
                                      </div>
                                    );
                                  }
                                )}
                            </div>
                          </div>
                        </div>
                        <div className="client-detail col-lg-12 col-xxl-3 ">
                          <div className="row border-20 border px-2 px-xl-4 mb-5 py-4">
                            <h5 className="fw-bold fst-italic">
                              {cookies?.user_data?.role === "client"
                                ? "Freelancer"
                                : "Client"}
                            </h5>
                            <div className="forcontact-dtsls">
                              {" "}
                              <div>
                                {" "}
                                {cookies?.user_data?.role === "professional" ? (
                                  <img
                                    src={contratData?.client_image}
                                    alt="img"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      border: "1px solid black",
                                      borderRadius: "50%",
                                    }}
                                    className="my-3"
                                  />
                                ) : (
                                  <img
                                    src={contratData?.professional_image}
                                    alt="img"
                                    style={{
                                      width: "50px",
                                      height: "50px",
                                      border: "1px solid black",
                                      borderRadius: "50%",
                                    }}
                                    className="my-3"
                                  />
                                )}
                              </div>
                              <div>
                                {" "}
                                <h5>
                                  {cookies?.user_data?.role === "professional"
                                    ? contratData?.client_name
                                    : contratData?.professional_name}
                                </h5>
                                {/* { / <span>Anterior</span > /}
                            { / <span>Its Thu 9:45PM</span > /} */}
                                <span>
                                  {new Date(
                                    contratData?.joined_at
                                  ).toLocaleDateString(undefined, {
                                    weekday: "long",
                                    year: "numeric",
                                    month: "long",
                                    day: "numeric",
                                  })}
                                </span>
                                {cookies?.user_data?.role === "client" ? (
                                  <div className="mt-2">
                                    <button
                                      className=" btn text-white me-2 mb-lg-2 mb-xxl-0"
                                      style={{ backgroundColor: "#00A78B" }}
                                      // onClick={() => {
                                      //   navigate(`chat`);
                                      // }}
                                    >
                                      <NavLink
                                        // onClick={() => {
                                        //   setCookie("chat", true);
                                        // }}

                                        // to="/chat"
                                        // style={{
                                        //   color: "white",
                                        //   textDecoration: "none",
                                        // }}
                                        className="text-decoration-none text-white"
                                        onClick={() => {
                                          axios
                                            .post(
                                              `${BaseUrl}/chat/get_room_id/`,
                                              {
                                                client_id:
                                                  cookies?.user_data.user_id,
                                                role: cookies?.user_data.role,
                                                user_token:
                                                  cookies?.user_data.user_token,
                                                professional_id:
                                                  contratData?.professional_id,
                                              }
                                            )
                                            .then((respo) => {
                                              if (
                                                respo?.data?.status ===
                                                "Success"
                                              ) {
                                                navigate("/chat");
                                                setCookie("chat", true);
                                              }
                                            });
                                        }}
                                      >
                                        Messsage
                                      </NavLink>
                                    </button>
                                    <button
                                      className=" btn "
                                      style={{
                                        borderColor: "#00A78B",
                                        color: "#00A78B",
                                      }}
                                      onClick={() => {
                                        navigate(
                                          `/professionalprofile/${contratData?.professional_id}`
                                        );
                                      }}
                                    >
                                      Re Hire
                                    </button>
                                  </div>
                                ) : null}
                                {cookies?.user_data?.role ===
                                  "professional" && (
                                  <>
                                    <div
                                      // to="/chat"
                                      onClick={() => {
                                        axios
                                          .post(
                                            `${BaseUrl}/chat/get_room_id/`,
                                            {
                                              client_id: contratData?.client_id,
                                              role: cookies?.user_data.role,
                                              user_token:
                                                cookies?.user_data.user_token,
                                              professional_id:
                                                cookies?.user_data?.user_id,
                                            }
                                          )
                                          .then((respo) => {
                                            if (
                                              respo?.data?.status === "Success"
                                            ) {
                                              setCookie("chat", true);
                                              navigate("/chat");
                                            }
                                          });
                                      }}
                                      className="text-decoration-none text-black chatTotheClinet"
                                    >
                                      <div className="py-md-0 py-2 Cur_proFes">
                                        <h4>Message</h4>
                                      </div>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row border-20 border px-2 px-xl-3 px-xxl-4 mb-5 py-4">
                            <h5 className="fw-bold fst-italic mb-3">
                              Contract Details
                            </h5>
                            <h5
                              className="fw-bold"
                              style={{
                                color: "#00A78B",
                                textTransform: "capitalize",
                              }}
                            >
                              {contratData?.contract_status}
                            </h5>

                            {/* { / <span>Since Oct 23, 2017</span > /} */}
                            <span> Since {contratData?.contract_started}</span>
                            <span>Contact ID {contratData?.contract_id}</span>

                            {cookies?.user_data?.role === "client" &&
                              contratData?.contract_status !== "completed" &&
                              contratData?.contract_status !== "terminated" && (
                                <div className="mt-2">
                                  <button
                                    className=" btn "
                                    style={{
                                      backgroundColor: "#00A78B",
                                      color: "white",
                                    }}
                                    onClick={() => {
                                      handleEndContractShow();
                                    }}
                                  >
                                    End Contract
                                  </button>
                                </div>
                              )}
                          </div>
                          {/* { / end contract modal / } */}
                          <Modal
                            centered
                            backdrop="static"
                            show={showEndContractModal}
                            onHide={() => {
                              handleEndContractClose();
                              setEndReasonerr(false);
                            }}
                          >
                            <Modal.Header>
                              <Modal.Title
                                style={{
                                  color: "#00A78B",
                                }}
                              >
                                End Contract
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="form-group">
                                <textarea
                                  required
                                  className="form-control end-contract-reason"
                                  onChange={(e) => {
                                    setEndReason(e.target.value);
                                    endReasonerr(false);
                                  }}
                                  cols="30"
                                  rows="4"
                                  placeholder="Reason to end contract"
                                ></textarea>
                                {!endReason && endReasonerr && (
                                  <span className="text-danger">
                                    Reason Required
                                  </span>
                                )}
                              </div>
                            </Modal.Body>
                            <Modal.Footer>
                              <Button
                                variant="secondary"
                                onClick={() => {
                                  handleEndContract();
                                }}
                              >
                                Sure
                              </Button>
                              <Button
                                style={{
                                  backgroundColor: "#00A78B",
                                  border: "none",
                                }}
                                onClick={handleEndContractClose}
                              >
                                Cancel
                              </Button>
                            </Modal.Footer>
                          </Modal>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </div>
      {/* {/ <Footer /> /} */}
    </>
  );
};

export default ClientContracts;
