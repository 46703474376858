import React, {
  useEffect,
  useState,
  useContext,
  useReducer,
  useRef,
} from "react";
import Slider from "react-touch-drag-slider";
import { GrClose } from "react-icons/gr";
import ReactLottie3 from "../../loader/ReactLottie3";
import Pagination from "react-bootstrap/Pagination";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { Header2 } from "../../components/Header";
import Button from "react-bootstrap/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { useNavigate, useLocation, Link, redirect } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { IoMdClose } from "react-icons/io";
import "react-toastify/dist/ReactToastify.css";
import $, { data } from "jquery";
import * as Yup from "yup";
import Global from "../../context/Global";
import { BsArrowRight } from "react-icons/bs";
import { GrLinkNext } from "react-icons/gr";
import Modal from "react-bootstrap/Modal";
import { BsPlusLg } from "react-icons/bs";
import { BiCategory } from "react-icons/bi";
import { BsCurrencyDollar } from "react-icons/bs";
import axios from "axios";
import { MultiSelect } from "react-multi-select-component";
import { GiCancel } from "react-icons/gi";
import Loader from "../../components/Loader";
import { grey } from "@mui/material/colors";

import { useCookies } from "react-cookie";
import { BaseUrl } from "../../BaseUrl";
const ProfessionalBuyAndSale = () => {
  const [disbutton, setDisbutton] = useState(false);
  const contextData = useContext(Global);
  const ref = useRef();

  const handleKeyDownPrice = (event) => {
    if (event.key === ".") {
      event.preventDefault();
    }
  };
  const [projectPageId, setProjectPageId] = useState({
    page: 1,
    page_size: 11,
  });
  const [sellDesigns, setSellDesigns] = useState();
  const [uploaded, setUploaded] = useState(null);
  const [lottie_loader, setlottiLoader] = useState(false);
  const [showStorage, setShowStorage] = useState(false);
  const [imgFileLength, setImgFileLength] = useState();
  const [imgmax, setImgmax] = useState(false);
  const [imgVald, setImgVald] = useState(false);

  const [imgLengthUpdt, setImgLengthUpdt] = useState();
  const [imgmaxUp, setImgmaxUp] = useState(false);

  const handleStoreClose = () => {
    setShowStorage(false);
    navigate("/subscription-plans");
  };

  const sendpagequery = () => {
    navigate({
      pathname: "/professional-buy-and-sale",
      query: `page=${page}`,
    });
  };

  const handleStorageShow = () => setShowStorage(true);

  const [catErr, setCatErr] = useState(false);
  const [clsstyle, setclsstyle] = useState("none");
  const [coverstyle, setcoverstyle] = useState("none");
  const [loader, setLoader] = useState(false);
  const [catagoriesDropdown, setCatagoriesDropdown] = useState([]);
  const [ziplbl, setziplbl] = useState("");
  const [imgPreview, setimgPreview] = useState("");
  const [coverPreview, setcoverPreview] = useState("");
  const [vidlbl, setvidlbl] = useState("");
  const location = useLocation();
  const [vidstyle, setvidstyle] = useState("none");
  const [zipstyle, setzipstyle] = useState("none");
  const isRedirect = location.state;
  const initialState = {
    preview_data_modal: false,
    upload_designs_modal_zip: false,
    upload_designs_modal_video: false,
    upload_designs_modal: false,
    sub_catagory_data: null,
    preview_catagory_data: [],
    preview_catagory_designs: [],
    selected_catagories: null,
    buy_sale_design_modal: false,
    edit_design_modal_video: false,
    edit_design_modal_zip: false,
    delete_project_modal: false,
  };

  const [projectId, setProjectId] = useState();
  const [imgclear, setimgclear] = useState("");
  const [coverclear, setcoverclear] = useState("");
  const [vidclear, setvidclear] = useState("");
  const [zipclear, setzipclear] = useState("");
  const [imgdisplay, setimgdisplay] = useState("none");
  const [coverdisplay, setcoverdisplay] = useState("none");
  const [viddisplay, setviddisplay] = useState("none");
  const [zipdisplay, setzipdisplay] = useState("none");

  const imgnull = (e) => {
    e.target.value = null;
  };
  const vidnull = (e) => {
    e.target.value = null;
  };
  const zipnull = (e) => {
    e.target.value = null;
  };

  const [cookies, setCookies] = useCookies();
  const [isRender, setIsRender] = useState(false);
  const navigate = useNavigate();
  useEffect(() => {
    if (cookies?.user_data) {
      if (cookies?.user_data?.role === "professional") {
        if (
          location?.state ||
          cookies?.user_data?.category_selected === false
        ) {
          setIsRender(true);
        } else {
          navigate("/client-architechture");
        }
      } else {
        navigate("/client-buy-sell");
      }
    } else {
      navigate("/select-sign-in");
    }
  }, []);
  useEffect(() => {
    if (!contextData?.static_buy_sale_design?.data?.length) {
      axios
        .get(`${BaseUrl}/quadra/sub_categories?category_id=3`)
        .then((res) => {
          contextData?.dispatch({
            type: "STATIC_BUY_SALE_DESIGN",
            value: res?.data,
          });
        });
    }
  }, []);

  const blankfields = () => {
    dispatch({ type: "UPLOAD_DESIGNS_MODAL", value: false });
    setclsstyle("none");
    setcoverstyle("none");
    setvidlbl("");
    setimgPreview("");
    setcoverPreview("");
    setziplbl("");
    setvidstyle("none");
    setzipstyle("none");
    setMessage(null);
    setMessageZip(null);
  };
  const reducer = (state, action) => {
    switch (action.type) {
      case "PREVIEW_DATA_MODAL":
        return { ...state, preview_data_modal: action.value };
      case "UPLOAD_DESIGNS_MODAL":
        return { ...state, upload_designs_modal: action.value };
      case "UPLOAD_DESIGNS_MODAL_ZIP":
        return { ...state, upload_designs_modal_zip: action.value };
      case "UPLOAD_DESIGNS_MODAL_VIDEO":
        return { ...state, upload_designs_modal_video: action.value };
      case "SUB_CATAGORY_DATA":
        return { ...state, sub_catagory_data: action.value };
      case "SUB_CATAGORY_DESIGNS":
        return { ...state, preview_catagory_designs: action.value };
      case "PREVIEW_CATAGORY_DATA":
        return { ...state, preview_catagory_data: action.value };
      case "SELECTED_CATAGORIES":
        return { ...state, selected_catagories: action.value };
      case "BUYSALE_DESIGN_UPLOAD_MODAL":
        return { ...state, buy_sale_design_modal: action.value };
      case "EDIT_DESIGNS_MODAL_VIDEO":
        return { ...state, edit_design_modal_video: action.value };
      case "EDIT_DESIGNS_MODAL_ZIP":
        return { ...state, edit_design_modal_zip: action.value };
      case "DELETE_PROJECT":
        return { ...state, delete_project_modal: action.value };
    }
  };
  const [state, dispatch] = useReducer(reducer, initialState);
  const toggleDisabled = (target) => {
    let IconTarget = document.getElementById(`${parseInt(target.id)}icon`);
    // setTargetId([parseInt(target.id)])
    if (target.checked) {
      IconTarget.src =
        contextData?.static_buy_sale_design?.data[
          parseInt(target.id)
        ].active_icon;
    } else {
      IconTarget.src =
        contextData?.static_buy_sale_design?.data[
          parseInt(target.id)
        ].unactive_icon;
    }
  };
  const [selectedCatagories, setSelectedCatagories] = useState(
    JSON.parse(localStorage.getItem("SelectedCatagories"))
  );

  const residentialCard = () => {
    $("main.dashboard-main").css("display", "none");
    $(".Residential-Architecture-page").css("display", "block");
  };

  const cardStyle = {
    textAlign: "center",
    height: "230px",
    borderRadius: "10px",
    cursor: "pointer",
  };

  const proSubCat = () => {
    axios
      .post(`${BaseUrl}/professional/professional_sub_cat`, {
        ...cookies?.user_data,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          dispatch({ type: "SELECTED_CATAGORIES", value: res?.data?.response });
        } else {
          dispatch({
            type: "SELECTED_CATAGORIES",
            value: { 1: [], 2: [], 3: [] },
          });
        }
      });
  };

  useEffect(() => {
    proSubCat();
  }, [state?.buy_sale_design_modal]);

  const languagesArchitecture = [
    contextData?.static_buy_sale_design?.data?.length &&
      contextData?.static_buy_sale_design?.data?.filter((ress) =>
        ress !== "" ||
        null ||
        (state?.selected_catagories &&
          state?.selected_catagories[3].includes(ress?.sub_category_id))
          ? {
              label: ress?.sub_category,
              value: ress?.sub_category_id,
            }
          : ""
      ),
  ];
  const newCatagoriesArchitecture =
    languagesArchitecture[0] &&
    languagesArchitecture[0].map((res) => {
      return (
        state?.selected_catagories &&
        !state?.selected_catagories[3].includes(res?.sub_category_id) && {
          label: res?.sub_category,
          value: res?.sub_category_id,
        }
      );
    });
  const newArchitecureOptionsArray =
    newCatagoriesArchitecture &&
    newCatagoriesArchitecture.filter((res) => {
      return res !== false;
    });

  const [modalSubCatagoryID, setModalSubCatagoryID] = useState("");
  const SetUpSchema = Yup.object().shape({
    price: Yup.number()
      .typeError("Price must be a number")
      .required("Please enter a price"),
    customize_price: Yup.number()
      .typeError("Customization Price must be a number")
      .required("Please Enter Customization Price"),
  });
  const [showImagesSection, setShowImagesSection] = useState(false);

  if (contextData.subCataImages !== undefined || null) {
  }
  const formSubmit = (valueArray) => {
    if (valueArray.length) {
      axios
        .post(`${BaseUrl}/professional/sel_sub_category`, {
          user_id: cookies?.user_data?.user_id,
          user_token: cookies?.user_data?.user_token,
          role: cookies?.user_data?.role,
          category: {
            cat_id: [...new Set([...selectedCatagories?.category?.cat_id, 3])],
          },
          sel_sub_cat: {
            ...selectedCatagories.sel_sub_cat,
            3: valueArray,
          },
        })
        .then((res) => {
          if (res?.data?.status === "Success") {
            setCookies("user_data", {
              ...cookies?.user_data,
              category_selected: true,
            });
            localStorage.removeItem("SelectedCatagories");
            localStorage.clear("SelectedCatagories");
            localStorage.clear();
            navigate("/professionaldashboard");
          }
        });
    } else {
      toast.error("You must select an category!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
  };
  const [page, setpage] = useState(1);
  const subCatData = () => {
    state?.sub_catagory_data?.CataId &&
      state?.sub_catagory_data?.SubCataId &&
      axios
        .post(`${BaseUrl}/professional/sub_cat_data`, {
          user_id: cookies?.user_data?.user_id,
          user_token: cookies?.user_data?.user_token,
          role: cookies?.user_data?.role,
          category_id: parseInt(state?.sub_catagory_data?.CataId),
          sub_category_id: state?.sub_catagory_data?.SubCataId,
          page_size: 11,
          page: page,
        })
        .then((res) => {
          dispatch({ type: "SUB_CATAGORY_DESIGNS", value: res?.data });
          setSellDesigns(res?.data?.data);
        })
        .catch((error) => {});
  };

  useEffect(() => {
    subCatData();
  }, [
    state?.sub_catagory_data,

    state?.upload_designs_modal_zip,
    state?.preview_data_modal,
    page,
  ]);

  // pagination for sell desing

  const projectPaginationArray = [];
  for (let i = 0; i < sellDesigns?.total_data / projectPageId?.page_size; i++) {
    projectPaginationArray.push(i + 1);
  }

  const backButtonFunc = () => {
    setShowImagesSection(false);
    // dispatch({ type: "SUB_CATAGORY_DESIGNS", value: null });
  };

  const handleSkipButton = () => {
    const list = [
      ...selectedCatagories?.sel_sub_cat["2"],
      ...selectedCatagories?.sel_sub_cat["1"],
    ];
    try {
      if (list.length && selectedCatagories) {
        axios
          .post(`${BaseUrl}/professional/sel_sub_category`, {
            user_id: cookies?.user_data?.user_id,
            user_token: cookies?.user_data?.user_token,
            role: cookies?.user_data?.role,
            category: {
              cat_id: [
                ...new Set([...selectedCatagories?.category?.cat_id, 3]),
              ],
            },
            sel_sub_cat: {
              ...selectedCatagories?.sel_sub_cat,
              3: [],
            },
          })
          .then((res) => {
            if (res?.data?.status === "Success") {
              setCookies("user_data", {
                ...cookies?.user_data,
                category_selected: true,
              });
              localStorage.clear();
              navigate("/professionaldashboard");
            }
          });
      } else {
        throw new Error("Please select at least one category");
      }
    } catch (error) {
      toast.error(error.message);
    }
  };

  const handleEditDesign = (data) => {
    // setLoader(true);
    setDisbutton(true);
    axios
      .post(`${BaseUrl}/professional/sell_design`, data, {
        onUploadProgress: (data) => {
          setUploaded(Math.round((data.loaded / data.total) * 100));
        },
      })
      .then((res) => {
        subCatData();
        // setLoader(false);
        setUploaded(null);
        setDisbutton(false);

        if (res?.data?.status === "Failed") {
          setLoader(false);
          setDisbutton(false);
        }

        setcoverstyle("none");
        setclsstyle("none");
        dispatch({ type: "PREVIEW_DATA_MODAL", value: false });
      })
      .catch((error) => {
        toast.error("Network  Error!");
        setcoverstyle("none");
        setclsstyle("none");
        setLoader(false);
        setDisbutton(false);
        dispatch({ type: "PREVIEW_DATA_MODAL", value: false });
      });
  };

  const [message, setMessage] = useState("");
  const [messageZip, setMessageZip] = useState("");

  const skipVideo = () => {
    const catagoryUploadForm = new FormData();
    catagoryUploadForm.append("user_id", cookies?.user_data?.user_id);
    catagoryUploadForm.append("user_token", cookies?.user_data?.user_token);
    catagoryUploadForm.append("role", cookies?.user_data?.role);
    catagoryUploadForm.append("sub_category_id", modalSubCatagoryID);
    catagoryUploadForm.append("category_id", "3");
    catagoryUploadForm.append("project_id", projectId);

    axios
      .post(`${BaseUrl}/professional/sell_design/video/`, catagoryUploadForm)
      .then((res) => {
        if (res?.data?.status === "Success") {
          setProjectId(projectId);
          dispatch({
            type: "UPLOAD_DESIGNS_MODAL_VIDEO",
            value: false,
          });
          dispatch({
            type: "UPLOAD_DESIGNS_MODAL_ZIP",
            value: true,
          });
          setLoader(false);
          blankfields();
          setimgdisplay("none");
          setcoverdisplay("none");
          setviddisplay("none");
          setzipdisplay("none");
          setclsstyle("none");
          setcoverstyle("none");
          setvidlbl("");
          setimgPreview("");
          setcoverPreview("");
          setziplbl("");
          setvidstyle("none");
          setzipstyle("none");
        } else if (
          res?.data?.status === "Failed" &&
          res?.data?.error_code == 108
        ) {
          setLoader(false);
          blankfields();
          handleStorageShow();
        } else {
          dispatch({
            type: "UPLOAD_DESIGNS_MODAL_VIDEO",
            value: true,
          });
          setLoader(false);
        }
      });
  };

  const deleteDesign = (project_id) => {
    setlottiLoader(true);
    axios
      .post(`${BaseUrl}/professional/sell_design/delete/`, {
        user_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        project_id: project_id,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          subCatData();
          setlottiLoader(false);
          dispatch({
            type: "DELETE_PROJECT",
            value: false,
            show: false,
          });
        } else {
          setlottiLoader(false);
          dispatch({
            type: "DELETE_PROJECT",
            value: false,
            show: false,
          });
        }
      });
  };

  // useEffect(() => {
  //   deleteDesign();
  // }, [state?.preview_data_modal]);
  if (contextData?.static_buy_sale_design?.data?.length && isRender) {
    return (
      <>
        <div className="create-account">
          <main className="create-account-main">
            <div className="container mb-5">
              <Header2 link={true} />
              <Formik
                initialValues={{}}
                onSubmit={(values, { setSubmitting }) => {
                  var valueArray = [];
                  for (let x in values) {
                    valueArray.push(values[x]);
                  }
                  formSubmit(valueArray);
                }}
              >
                {({ isSubmitting, setFieldValue, values }) => (
                  <Form>
                    <>
                      {showImagesSection ? "" : <h1>Choose Categories</h1>}
                      <div className="category-button">
                        <h6 className="text-center">Designs I Want To Sell</h6>
                      </div>

                      <br />
                      {isRedirect && !showImagesSection ? (
                        <Link
                          to={"/professionaldashboard"}
                          className="me-auto"
                          style={{
                            fontSize: "25px",
                            color: "#01a78a",
                            cursor: "pointer",
                          }}
                          state={{ fromHomePage: false }}
                        >
                          <i
                            className="fa-solid fa-arrow-left-long"
                            style={{ fontSize: "30px" }}
                            onClick={() => {
                              setShowImagesSection(false);
                            }}
                          ></i>
                        </Link>
                      ) : (
                        <div
                          className="me-auto"
                          style={{
                            fontSize: "25px",
                            color: "#01a78a",
                            cursor: "pointer",
                          }}
                        >
                          <i
                            style={{ fontSize: "30px" }}
                            className="fa-solid fa-arrow-left-long"
                            onClick={() => {
                              showImagesSection
                                ? backButtonFunc()
                                : navigate("/categoryvisualization");
                            }}
                          ></i>
                        </div>
                      )}
                      {showImagesSection ? (
                        <div className="row">
                          {state?.preview_catagory_designs?.data &&
                            state?.preview_catagory_designs?.data?.final_data.map(
                              (res, index) => {
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className="col-lg-4 my-3 col-md-6"
                                    >
                                      <div
                                        style={{
                                          height: "240px",
                                          borderRadius: "30px",
                                        }}
                                        className="card border-0 flex-row bg-dark text-white visibleForEdit"
                                      >
                                        <img
                                          src={res.cover_image}
                                          className="card-img"
                                          alt="..."
                                          style={{
                                            borderRadius: "30px",
                                          }}
                                        />

                                        <div
                                          className="card-img-overlay"
                                          style={{
                                            display: "flex",
                                            justifyContent: "flex-end",
                                            flexDirection: "column",
                                            borderRadius: "30px",
                                          }}
                                        >
                                          <h4 className="card-title cardTitleVisible">
                                            ${res.price}/ project
                                          </h4>
                                          <div className="row">
                                            <div
                                              className="col-xxl-6 col-md-12 col-6"
                                              style={{ padding: "6px" }}
                                            >
                                              <button
                                                id={index}
                                                ref={ref}
                                                type="button"
                                                className="btn text-white"
                                                style={{
                                                  width: "100%",
                                                  fontSize: "14px",
                                                  backgroundColor:
                                                    "rgb(0, 167, 139)",
                                                }}
                                                onClick={(e) => {
                                                  setclsstyle("block");
                                                  setcoverstyle("block");
                                                  setvidstyle("block");
                                                  setzipstyle("block");
                                                  dispatch({
                                                    type: "PREVIEW_CATAGORY_DATA",
                                                    value: {
                                                      type: "edit",
                                                      index: index,
                                                    },
                                                  });
                                                  dispatch({
                                                    type: "PREVIEW_DATA_MODAL",
                                                    value: true,
                                                  });
                                                }}
                                              >
                                                Edit Design
                                              </button>
                                            </div>
                                            <div
                                              className="col-xxl-6 col-md-12 col-6"
                                              style={{ padding: "6px" }}
                                            >
                                              <button
                                                type="button"
                                                className="btn text-white"
                                                style={{
                                                  width: "100%",
                                                  fontSize: "14px",
                                                  backgroundColor:
                                                    "rgb(0, 167, 139)",
                                                }}
                                                onClick={() => {
                                                  dispatch({
                                                    type: "PREVIEW_CATAGORY_DATA",
                                                    value: {
                                                      image: res.image,
                                                      type: "image",
                                                    },
                                                  });
                                                  dispatch({
                                                    type: "PREVIEW_DATA_MODAL",
                                                    value: true,
                                                  });
                                                }}
                                              >
                                                Preview Image
                                              </button>
                                            </div>
                                            {res.has_video == true ? (
                                              <>
                                                <div
                                                  className="col-xxl-6 col-md-12 col-6"
                                                  style={{ padding: "6px" }}
                                                >
                                                  <button
                                                    type="button"
                                                    className="btn text-white"
                                                    style={{
                                                      width: "100%",
                                                      fontSize: "14px",
                                                      backgroundColor:
                                                        "rgb(0, 167, 139)",
                                                    }}
                                                    onClick={() => {
                                                      dispatch({
                                                        type: "PREVIEW_CATAGORY_DATA",
                                                        value: {
                                                          video: res.video,
                                                          type: "video",
                                                        },
                                                      });
                                                      dispatch({
                                                        type: "PREVIEW_DATA_MODAL",
                                                        value: true,
                                                      });
                                                    }}
                                                  >
                                                    Preview Video
                                                  </button>
                                                </div>
                                              </>
                                            ) : null}

                                            <div
                                              className="col-xxl-6 col-md-12 col-6"
                                              style={{ padding: "6px" }}
                                            >
                                              <button
                                                type="button"
                                                className="btn text-white"
                                                style={{
                                                  width: "100%",
                                                  fontSize: "14px",
                                                  backgroundColor:
                                                    "rgb(0, 167, 139)",
                                                }}
                                                // onClick={() => {
                                                //   deleteDesign(res?.id);
                                                // }}

                                                onClick={() => {
                                                  dispatch({
                                                    type: "DELETE_PROJECT",
                                                    value: {
                                                      show: true,
                                                      index: res?.id,
                                                    },
                                                  });
                                                }}
                                              >
                                                Delete
                                              </button>
                                            </div>

                                            {cookies?.user_data.role ===
                                            "client" ? (
                                              <div
                                                className="col-xxl-6 col-md-12 col-6"
                                                style={{ padding: "6px" }}
                                              >
                                                <button
                                                  type="button"
                                                  className="btn text-white"
                                                  style={{
                                                    width: "100%",
                                                    fontSize: "14px",
                                                    backgroundColor:
                                                      "rgb(0, 167, 139)",
                                                  }}
                                                  onClick={() => {
                                                    dispatch({
                                                      type: "PREVIEW_CATAGORY_DATA",
                                                      value: {
                                                        zip: `${state?.preview_catagory_designs?.project_url}${state?.preview_catagory_designs?.project[index]}`,
                                                        type: "zip",
                                                      },
                                                    });
                                                    dispatch({
                                                      type: "PREVIEW_DATA_MODAL",
                                                      value: true,
                                                    });
                                                  }}
                                                >
                                                  Buy Zip
                                                </button>
                                              </div>
                                            ) : (
                                              ""
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              }
                            )}
                          <div
                            className="col-lg-4 my-3 col-md-6"
                            onClick={() =>
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL",
                                value: true,
                              })
                            }
                          >
                            <div
                              style={cardStyle}
                              className="border   d-flex align-items-center flex-column  browse-project-card justify-content-center residental-card"
                            >
                              <h6 className="pt-3 theme-text-color fs-21">
                                Upload Project
                                <BsPlusLg className="theme-text-color ms-3" />
                              </h6>
                            </div>
                          </div>
                          <div className=" col-12 my-md-4 my-3 d-flex align-items-center justify-content-end">
                            {/* <button
                              type="submit"
                              className="create-account-btn"
                              onClick={() =>
                                isRedirect
                                  ? navigate("/professionaldashboard")
                                  : ""
                              }
                            >
                              Continue{" "}
                              <BsArrowRight style={{ color: "white" }} />
                            </button> */}
                          </div>
                        </div>
                      ) : (
                        <div className="row">
                          {contextData?.static_buy_sale_design?.data?.length &&
                            contextData?.static_buy_sale_design?.data.map(
                              (res, i) => {
                                return (
                                  <>
                                    {!isRedirect ? (
                                      <div
                                        className="col-sm-6 my-md-4 px-lg-5 px-md-2"
                                        key={i}
                                      >
                                        <div
                                          className=" px-1 shadow-box"
                                          style={{
                                            position: "relative",
                                            overflow: "hidden",
                                          }}
                                        >
                                          <div className="row  category-box">
                                            <div className="col-md-3 col-12 h-100 text-center  px-2">
                                              <div className="p-md-1 p-lg-3 icon-box">
                                                <img
                                                  id={i + "icon"}
                                                  src={res?.unactive_icon}
                                                  alt={res?.sub_category}
                                                />
                                              </div>
                                            </div>
                                            <div className="col-md-9 col-12 d-sm-flex align-items-center px-3 p-md-0">
                                              <div>
                                                <h6 className="m-0 py-2 text-md-start text-center">
                                                  {res?.sub_category}
                                                </h6>
                                                <input
                                                  type="checkbox"
                                                  id={`${i}checkbox`}
                                                  name={res?.sub_category_id}
                                                  className="large-checkbox"
                                                  style={{ cursor: "pointer" }}
                                                  onInput={(e) => {
                                                    if (e.target.checked) {
                                                      setFieldValue(
                                                        `${i}checkbox`,
                                                        res?.sub_category_id
                                                      );
                                                    } else {
                                                      setFieldValue(
                                                        `${i}checkbox`
                                                      );
                                                    }
                                                    toggleDisabled(e.target);
                                                  }}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </div>

                                        <div
                                          className="text-danger my-2"
                                          id={`error${i}`}
                                        ></div>
                                      </div>
                                    ) : (
                                      state?.selected_catagories &&
                                      state?.selected_catagories.length !== 0 &&
                                      state?.selected_catagories[3].includes(
                                        res?.sub_category_id
                                      ) && (
                                        <div
                                          className="col-lg-4 my-3 col-md-6"
                                          onClick={() => {
                                            setShowImagesSection(true);
                                            setModalSubCatagoryID(
                                              res?.sub_category_id
                                            );
                                            dispatch({
                                              type: "SUB_CATAGORY_DATA",
                                              value: {
                                                CataName: res.sub_category,
                                                CataId: res.category_id,
                                                SubCataId: res.sub_category_id,
                                              },
                                            });
                                          }}
                                        >
                                          <div
                                            style={cardStyle}
                                            className="dashboard-theme-color d-flex align-items-center flex-column text-white browse-project-card justify-content-center residental-card"
                                            onClick={residentialCard}
                                          >
                                            <img
                                              src={res?.unactive_icon}
                                              alt=""
                                              style={{
                                                filter: "brightness(4.5)",
                                              }}
                                            />
                                            <h6 className="pt-3">
                                              {res?.sub_category}
                                            </h6>
                                          </div>
                                        </div>
                                      )
                                    )}
                                  </>
                                );
                              }
                            )}
                          {isRedirect ? (
                            <div
                              className="col-lg-4 my-3 col-md-6"
                              onClick={() =>
                                dispatch({
                                  type: "BUYSALE_DESIGN_UPLOAD_MODAL",
                                  value: true,
                                })
                              }
                            >
                              <div
                                style={cardStyle}
                                className="border   d-flex align-items-center flex-column  browse-project-card justify-content-center residental-card"
                              >
                                <h6 className="pt-3 theme-text-color fs-21">
                                  Add Sub Catagory
                                  <BsPlusLg className="theme-text-color ms-3" />
                                </h6>
                              </div>
                            </div>
                          ) : (
                            ""
                          )}

                          <div className=" col-12 my-md-4 my-3 d-flex align-items-center justify-content-end">
                            {!isRedirect ? (
                              <button
                                type="button"
                                style={{ border: "1px solid" }}
                                className="bg-white theme-text-color create-account-btn"
                                onClick={handleSkipButton}
                              >
                                Skip{" "}
                                <BsArrowRight className="theme-text-color" />
                              </button>
                            ) : (
                              ""
                            )}
                            <button
                              type="submit"
                              className="create-account-btn"
                              onClick={() =>
                                isRedirect
                                  ? navigate("/professionaldashboard")
                                  : ""
                              }
                            >
                              Continue{" "}
                              <BsArrowRight style={{ color: "white" }} />
                            </button>
                          </div>
                        </div>
                      )}
                    </>
                    {showImagesSection &&
                      (projectPaginationArray?.length >= 11 ? (
                        <Pagination className="ps-5 paginationBoxProfessionalDashboard">
                          <Pagination.First
                            onClick={() => {
                              setProjectId({
                                page: 1,
                                page_size: 12,
                              });
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setProjectId((prev) => ({
                                ...prev,
                                page:
                                  projectPageId?.page !== 1
                                    ? projectPageId?.page - 1
                                    : 1,
                              }));
                            }}
                          />
                          {projectPaginationArray?.map((res, key) => (
                            <Pagination.Item
                              key={key}
                              active={projectPageId?.page === res}
                              onClick={(e) => {
                                setpage(res);
                              }}
                            >
                              {res}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => {
                              setProjectId((prev) => ({
                                ...prev,
                                page:
                                  projectPaginationArray?.length !==
                                  projectPageId?.page
                                    ? projectPageId?.page + 1
                                    : projectPageId?.page,
                              }));
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setProjectId((prev) => ({
                                ...prev,
                                page: projectPaginationArray?.length,
                              }));
                            }}
                          />
                        </Pagination>
                      ) : null)}
                  </Form>
                )}
              </Formik>

              {/* delete category modal */}

              <Modal
                backdrop="static"
                keyboard={false}
                show={state?.delete_project_modal?.show}
                centered
                onHide={() => {
                  dispatch({
                    type: "DELETE_PROJECT",
                    value: false,
                    show: false,
                  });
                  // setCatErr(false);
                  setlottiLoader(false);
                }}
              >
                <Modal.Header closeButton>
                  <Modal.Title className="fs-20">
                    Are You Sure You Want to Delete this Design?
                  </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-flex justify-content-end">
                    <Button
                      disabled={lottie_loader ? true : false}
                      className="theme-text-color bg-white mx-2"
                      style={{ border: "2px solid" }}
                      onClick={() => {
                        dispatch({
                          type: "DELETE_PROJECT",
                          value: false,
                          show: false,
                        });
                        // setlottiLoader(false);
                      }}
                    >
                      Close
                    </Button>
                    <Button
                      className="theme-bg-color border-0 mx-2"
                      disabled={lottie_loader ? true : false}
                      onClick={() =>
                        deleteDesign(state?.delete_project_modal?.index)
                      }
                    >
                      {lottie_loader ? <ReactLottie3 /> : "Delete Project"}
                      {/* Delete Project */}
                    </Button>
                  </div>
                </Modal.Body>
              </Modal>

              {/* add catagories modal */}

              <Modal
                show={state?.buy_sale_design_modal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalProfessionalDashboard"
              >
                <Modal.Header
                  closeButton
                  style={{ margin: "0 0 0 auto" }}
                  onClick={() => {
                    dispatch({
                      type: "BUYSALE_DESIGN_UPLOAD_MODAL",
                      value: false,
                    });
                    setCatErr(false);
                  }}
                  className="border-0"
                ></Modal.Header>
                <Modal.Body>
                  <h4>Add Catagories</h4>
                  <Formik
                    initialValues={{
                      new_sub_cat: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (catagoriesDropdown.length < 1) {
                        setCatErr(true);
                        return false;
                      }
                      dispatch({
                        type: "BUYSALE_DESIGN_UPLOAD_MODAL",
                        value: false,
                      });

                      axios
                        .put(`${BaseUrl}/professional/sel_sub_category`, {
                          ...cookies?.user_data,
                          category_id: "3",
                          new_sub_cat: values?.new_sub_cat,
                        })
                        .then((res) => {
                          return res?.data?.status === "Success"
                            ? (dispatch({
                                type: "BUYSALE_DESIGN_UPLOAD_MODAL",
                                value: false,
                              }),
                              proSubCat(),
                              setCatagoriesDropdown([]))
                            : "";
                        });
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <Form>
                        <div className="row">
                          <div className="col">
                            <MultiSelect
                              options={newArchitecureOptionsArray}
                              value={catagoriesDropdown}
                              onChange={(catagoriesDropdown) => {
                                setCatErr(false);
                                setFieldValue(
                                  "new_sub_cat",
                                  catagoriesDropdown.map((val) => val?.value)
                                );
                                setCatagoriesDropdown(catagoriesDropdown);
                              }}
                              labelledBy="Select"
                              name="new_sub_cat"
                            />
                          </div>
                        </div>
                        {catErr ? (
                          <span className="text-danger mt-2">
                            Minimum one category select
                          </span>
                        ) : (
                          ""
                        )}
                        <div className="row">
                          <div className="col d-flex justify-content-center pt-5">
                            <button
                              type="submit"
                              className="ModalCategorySubmit mx-auto"
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </Form>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
              {/* add catagories modal */}

              {/* design upload modal */}

              <Modal
                disabled={true}
                show={state?.upload_designs_modal}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalProfessionalDashboard"
              >
                <button
                  style={{
                    border: "0",
                    background: "none",
                    textAlign: "end",
                    marginRight: "3%",
                    marginTop: "2%",
                  }}
                  disabled={disbutton ? true : false}
                  onClick={() => {
                    blankfields();
                    setImgmax(false);

                    dispatch({
                      type: "UPLOAD_DESIGNS_MODAL",
                      value: false,
                    });
                  }}
                >
                  <IoMdClose size={25} />
                </button>
                <Modal.Body>
                  <h4>Upload Your Price, Image.</h4>
                  <Formik
                    initialValues={{
                      ...cookies?.user_data,
                      price: "",
                      image: "",
                      cover_image: "",
                      customize_price: "",
                    }}
                    validationSchema={SetUpSchema}
                    onSubmit={(values, { setSubmitting }) => {
                      if (!values?.image || !values?.cover_image) {
                        if (!values?.image) {
                          setimgdisplay("block");
                        }
                        if (!values?.cover_image) {
                          setcoverdisplay("block");
                        }

                        return false;
                      } else if (imgFileLength > 10) {
                        setImgmax(true);
                        return false;
                      } else {
                        const catagoryUpload = new FormData();
                        catagoryUpload.append(
                          "user_id",
                          cookies?.user_data?.user_id
                        );
                        catagoryUpload.append(
                          "user_token",
                          cookies?.user_data?.user_token
                        );
                        catagoryUpload.append("role", cookies?.user_data?.role);
                        catagoryUpload.append(
                          "sub_category_id",
                          modalSubCatagoryID
                        );
                        catagoryUpload.append("category_id", "3");

                        Object.values(values?.image).map((it) => {
                          catagoryUpload.append("image", it);
                        });
                        catagoryUpload.append("price", values?.price);

                        catagoryUpload.append(
                          "customize_price",
                          values?.customize_price
                        );
                        catagoryUpload.append(
                          "cover_image",
                          values?.cover_image
                        );

                        // setLoader(true);

                        setDisbutton(true);

                        axios
                          .post(
                            `${BaseUrl}/professional/sell_design`,
                            catagoryUpload,
                            {
                              onUploadProgress: (data) => {
                                setUploaded(
                                  Math.round((data.loaded / data.total) * 100)
                                );
                              },
                            }
                          )
                          .then((res) => {
                            if (res?.data?.status === "Success") {
                              setProjectId(res?.data?.data?.project_id);
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL",
                                value: false,
                              });
                              setUploaded(null);
                              setDisbutton(false);

                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL_VIDEO",
                                value: true,
                              });

                              setLoader(false);
                              blankfields();
                              setimgdisplay("none");
                              setcoverdisplay("none");
                              setviddisplay("none");
                              setzipdisplay("none");
                              setclsstyle("none");
                              setcoverstyle("none");
                              setvidlbl("");
                              setimgPreview("");
                              setcoverPreview("");
                              setziplbl("");
                              setvidstyle("none");
                              setzipstyle("none");
                            } else if (
                              res?.data?.status === "Failed" &&
                              res?.data?.error_code == 108
                            ) {
                              setDisbutton(false);
                              blankfields();
                              handleStorageShow();
                            } else {
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL",
                                value: true,
                              });
                              // setLoader(false);
                            }
                          })
                          .catch((error) => {
                            toast.error("Network  Error!");
                            dispatch({
                              type: "UPLOAD_DESIGNS_MODAL",
                              value: false,
                            });
                            setUploaded(null);
                            setDisbutton(false);
                            blankfields();
                            setimgdisplay("none");
                            setcoverdisplay("none");
                            setviddisplay("none");
                            setzipdisplay("none");
                            setclsstyle("none");
                            setcoverstyle("none");
                            setvidlbl("");
                            setimgPreview("");
                            setcoverPreview("");
                            setziplbl("");
                            setvidstyle("none");
                            setzipstyle("none");
                          });
                      }
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <>
                        <Form>
                          <div className="row">
                            <div className="col-lg-6">
                              <div className="selectprice">
                                <BsCurrencyDollar />
                                <Field
                                  type="number"
                                  placeholder="Enter Your project Price "
                                  className="priceInput"
                                  name="price"
                                  onKeyDown={handleKeyDownPrice}
                                />
                                <ErrorMessage
                                  name="price"
                                  component="div"
                                  className="m-2 text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 uploadimageCat">
                              <div
                                className="selectCategoryMain h-100 d-flex align-items-center "
                                style={{
                                  border: "1px solid rgb(118, 118, 118)",
                                  borderRadius: "5px",
                                }}
                              >
                                <BiCategory />
                                <p className="m-0 ">Upload Cover Image</p>

                                <div
                                  className={coverstyle}
                                  style={{
                                    width: "50px",
                                    height: "45px",
                                    marginLeft: "23%",
                                  }}
                                >
                                  <img
                                    src={coverPreview}
                                    alt="preview"
                                    style={{ height: "100%", width: "100%" }}
                                  />

                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "34%",
                                      top: "8%",
                                    }}
                                  >
                                    <GiCancel
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      onClick={() => {
                                        imgnull(coverclear);
                                        setcoverstyle("none");
                                        setFieldValue("cover_image", null);
                                      }}
                                    />
                                  </span>
                                </div>

                                <input
                                  type="file"
                                  className="inputImageFeaturedImage"
                                  onInput={(e) => {
                                    setFieldValue(
                                      "cover_image",
                                      e.target.files[0]
                                    );
                                    setcoverPreview(
                                      URL.createObjectURL(e.target.files[0])
                                    );

                                    setcoverstyle("block");
                                    setcoverclear(e);
                                    setcoverdisplay("none");
                                  }}
                                  accept="image/png, image/gif, image/jpeg,image/jpg"
                                />
                              </div>
                              <span
                                style={{ color: "red", paddingTop: "7px" }}
                                className={coverdisplay}
                              >
                                Cover Image Required
                              </span>
                            </div>
                          </div>

                          <div className="row mt-5 ">
                            <div className="col-lg-6 ">
                              <div className="selectprice">
                                <BsCurrencyDollar />
                                <Field
                                  type="number"
                                  placeholder="Enter Your Customization Price in Sq m"
                                  className="priceInput"
                                  name="customize_price"
                                  onKeyDown={handleKeyDownPrice}
                                />
                                <ErrorMessage
                                  name="customize_price"
                                  component="div"
                                  className="m-2 text-danger"
                                />
                              </div>
                            </div>
                            <div className="col-lg-6 uploadimageCat">
                              <div
                                className="selectCategoryMain h-100 d-flex align-items-center"
                                style={{
                                  border: "1px solid rgb(118, 118, 118)",
                                  borderRadius: "5px",
                                }}
                              >
                                <BiCategory />
                                <p className="m-0 ">Upload Preview Images</p>
                                {imgFileLength < 11 ? (
                                  <div
                                    className={clsstyle}
                                    style={{
                                      width: "50px",
                                      height: "45px",
                                      marginLeft: "20%",
                                    }}
                                  >
                                    <img
                                      src={imgPreview}
                                      alt="preview"
                                      style={{ height: "100%", width: "100%" }}
                                    />

                                    <span
                                      style={{
                                        position: "absolute",
                                        right: "34%",
                                        top: "8%",
                                      }}
                                    >
                                      <GiCancel
                                        style={{
                                          cursor: "pointer",
                                        }}
                                        onClick={() => {
                                          imgnull(imgclear);
                                          setclsstyle("none");
                                          setFieldValue("image", null);
                                        }}
                                      />
                                    </span>
                                  </div>
                                ) : null}
                                <input
                                  type="file"
                                  className="inputImageFeaturedImage"
                                  onInput={(e) => {
                                    setFieldValue("image", e.target.files);
                                    setimgPreview(
                                      URL.createObjectURL(e.target.files[0])
                                    );
                                    const selectImg = e.target.files.length;

                                    setImgFileLength(selectImg);

                                    if (selectImg > 10) {
                                      setclsstyle("none");

                                      setImgmax(true);
                                    } else {
                                      setImgmax(false);
                                    }
                                    setclsstyle("block");
                                    setimgclear(e);
                                    setimgdisplay("none");
                                  }}
                                  multiple
                                  accept="image/png, image/gif, image/jpeg,image/jpg"
                                />
                              </div>
                              <span
                                style={{ color: "red", paddingTop: "7px" }}
                                className={imgdisplay}
                              >
                                Image required
                              </span>
                              {imgmax ? (
                                <>
                                  {" "}
                                  <span
                                    style={{ color: "red", paddingTop: "7px" }}
                                  >
                                    You can select up to 10 images
                                  </span>
                                </>
                              ) : null}
                            </div>
                          </div>
                          {uploaded > 0 && (
                            <div className="progress-file-upload my-3">
                              <div className="progress ">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${uploaded}%` }}
                                  aria-valuenow={uploaded}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >{`${uploaded}%`}</div>
                              </div>
                            </div>
                          )}
                          <div className="row py-5">
                            <div className="d-flex justify-content-center">
                              <button
                                disabled={disbutton ? true : false}
                                type="submit"
                                className="ModalCategorySubmit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>

              <Modal
                show={state?.upload_designs_modal_video}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalProfessionalDashboard"
              >
                {/* <Modal.Header
                  closeButton
                  disabled={disbutton ? true : false}
                  style={{ margin: "0 0 0 auto" }}
                  onClick={() => {
                    blankfields();
                    dispatch({
                      type: "UPLOAD_DESIGNS_MODAL_VIDEO",
                      value: false,
                    });
                  }}
                  className="border-0"
                ></Modal.Header> */}
                <Modal.Body>
                  <h4>Upload Your Video.</h4>
                  <Formik
                    initialValues={{
                      ...cookies?.user_data,

                      video: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (!values?.video) {
                        setviddisplay("block");
                      } else {
                        const catagoryUpload = new FormData();
                        catagoryUpload.append(
                          "user_id",
                          cookies?.user_data?.user_id
                        );
                        catagoryUpload.append(
                          "user_token",
                          cookies?.user_data?.user_token
                        );
                        catagoryUpload.append("role", cookies?.user_data?.role);
                        catagoryUpload.append(
                          "sub_category_id",
                          modalSubCatagoryID
                        );
                        catagoryUpload.append("category_id", "3");
                        catagoryUpload.append("project_id", projectId);

                        catagoryUpload.append("video", values?.video);

                        setDisbutton(true);
                        // setLoader(true);
                        axios
                          .post(
                            `${BaseUrl}/professional/sell_design/video/`,
                            catagoryUpload,
                            {
                              onUploadProgress: (data) => {
                                setUploaded(
                                  Math.round((data.loaded / data.total) * 100)
                                );
                              },
                            }
                          )
                          .then((res) => {
                            if (res?.data?.status === "Success") {
                              setProjectId(projectId);
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL_VIDEO",
                                value: false,
                              });
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL_ZIP",
                                value: true,
                              });

                              setUploaded(null);
                              setDisbutton(false);

                              // setLoader(false);
                              blankfields();
                              setimgdisplay("none");
                              setcoverdisplay("none");
                              setviddisplay("none");
                              setzipdisplay("none");
                              setclsstyle("none");
                              setvidlbl("");
                              setimgPreview("");
                              setziplbl("");
                              setvidstyle("none");
                              setzipstyle("none");
                            } else if (
                              res?.data?.status === "Failed" &&
                              res?.data?.error_code == 108
                            ) {
                              setLoader(false);
                              blankfields();
                              handleStorageShow();
                            } else {
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL_VIDEO",
                                value: true,
                              });
                              setLoader(false);
                            }
                          })
                          .catch((error) => {
                            toast.error("Network  Error!");
                            setLoader(false);
                            setDisbutton(false);
                            blankfields();
                            setUploaded(null);

                            dispatch({
                              type: "UPLOAD_DESIGNS_MODAL_VIDEO",
                              value: false,
                            });
                          });
                      }
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <>
                        <Form>
                          <div className="row m-0 pb-3 mb-3   ">
                            <div className="col-12 ps-0">
                              <div className="d-flex imageDropBoxDashboardProfessional align-items-center">
                                <button
                                  className="w-100 pointer"
                                  type="button"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <BsPlusLg
                                    className="pointer"
                                    style={{
                                      color: "#fff",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <span className="ps-2 cursor-pointer">
                                    Upload Video
                                  </span>
                                </button>
                                <div
                                  className={vidstyle}
                                  style={{ marginTop: "2%", cursor: "pointer" }}
                                >
                                  <span>{vidlbl}</span>
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10%",
                                      bottom: "10%",
                                    }}
                                  >
                                    <GiCancel
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      size={25}
                                      color="gray"
                                      onClick={() => {
                                        setvidstyle("none");
                                        setvidlbl("");
                                        vidnull(vidclear);
                                        setFieldValue("video", null);
                                      }}
                                    />
                                  </span>
                                </div>
                                <p className="ps-4 "></p>
                                <input
                                  type="file"
                                  accept="video/*"
                                  name="project"
                                  className="pointer"
                                  onChange={(e) => {
                                    let sizeOfFile = e.target.files[0].size;

                                    if (sizeOfFile > 500e6) {
                                      setFieldValue(null);
                                      setvidlbl(null);
                                      setvidstyle("none");
                                      let msg =
                                        "Video size should be less then or equal to  500MB";
                                      setMessage(msg);
                                    } else {
                                      setMessage(null);
                                      setFieldValue("video", e.target.files[0]);
                                      setvidclear(e);
                                      let name = e.target.files[0].name;
                                      const maxLength = 20;
                                      const trimmedFileName =
                                        name.length > maxLength
                                          ? name.slice(0, maxLength) +
                                            "..." +
                                            name.slice(-4)
                                          : name;
                                      setvidlbl(trimmedFileName);
                                      setvidstyle("block");
                                      setviddisplay("none");
                                    }
                                  }}
                                />
                              </div>
                              <p className="text-danger">{message}</p>
                              <span className={`${viddisplay} text-danger`}>
                                Upload a video
                              </span>
                            </div>
                          </div>
                          {uploaded > 0 && (
                            <div className="progress-file-upload mb-5">
                              <div className="progress ">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${uploaded}%` }}
                                  aria-valuenow={uploaded}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >{`${uploaded}%`}</div>
                              </div>
                            </div>
                          )}

                          {uploaded == 100 && (
                            <div className="my-1">
                              <p className="text-center">Keep waiting...</p>
                            </div>
                          )}
                          <div className="videoForbuy pb-5">
                            <div>
                              <button
                                disabled={disbutton ? true : false}
                                type="button"
                                onClick={skipVideo}
                                className="ModalCategorySubmit"
                              >
                                Skip
                              </button>
                            </div>
                            <div>
                              <button
                                disabled={disbutton ? true : false}
                                type="submit"
                                className="ModalCategorySubmit"
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>

              {/* Model for UPLOAD ZIP */}
              <Modal
                show={state?.upload_designs_modal_zip}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalProfessionalDashboard"
              >
                <button
                  style={{
                    border: "0",
                    background: "none",
                    textAlign: "end",
                    marginRight: "3%",
                    marginTop: "2%",
                  }}
                  disabled={disbutton ? true : false}
                  onClick={() => {
                    blankfields();
                    dispatch({
                      type: "UPLOAD_DESIGNS_MODAL_ZIP",
                      value: false,
                    });
                  }}
                >
                  <IoMdClose size={25} />
                </button>
                <Modal.Body>
                  <h4>Upload Your Project.</h4>
                  <Formik
                    initialValues={{
                      ...cookies?.user_data,

                      project: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (!values?.project) {
                        setzipdisplay("block");
                        return false;
                      } else {
                        const catagoryUpload = new FormData();
                        catagoryUpload.append(
                          "user_id",
                          cookies?.user_data?.user_id
                        );
                        catagoryUpload.append(
                          "user_token",
                          cookies?.user_data?.user_token
                        );
                        catagoryUpload.append("role", cookies?.user_data?.role);
                        catagoryUpload.append(
                          "sub_category_id",
                          modalSubCatagoryID
                        );
                        catagoryUpload.append("category_id", "3");
                        catagoryUpload.append("project_id", projectId);
                        catagoryUpload.append("project", values?.project);
                        setDisbutton(true);
                        // setLoader(true);
                        axios
                          .post(
                            `${BaseUrl}/professional/sell_design/project/`,
                            catagoryUpload,
                            {
                              onUploadProgress: (data) => {
                                setUploaded(
                                  Math.round((data.loaded / data.total) * 100)
                                );
                              },
                            }
                          )
                          .then((res) => {
                            if (res?.data?.status === "Success") {
                              subCatData();
                              setProjectId(res?.data?.data?.project_id);
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL_ZIP",
                                value: false,
                              });
                              setDisbutton(false);

                              setUploaded(null);
                              setLoader(false);
                              blankfields();
                              setimgdisplay("none");
                              setcoverdisplay("none");
                              setviddisplay("none");
                              setzipdisplay("none");
                              setclsstyle("none");
                              setvidlbl("");
                              setimgPreview("");
                              setziplbl("");
                              setvidstyle("none");
                              setzipstyle("none");
                            } else if (
                              res?.data?.status === "Failed" &&
                              res?.data?.error_code == 108
                            ) {
                              setDisbutton(false);

                              setLoader(false);
                              blankfields();
                              handleStorageShow();
                            } else {
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL_ZIP",
                                value: true,
                              });
                              // setLoader(false);
                            }
                          })
                          .catch((err) => {
                            toast.error("Network  Error!");
                            dispatch({
                              type: "UPLOAD_DESIGNS_MODAL_ZIP",
                              value: false,
                            });
                            setDisbutton(false);

                            setUploaded(null);
                            setLoader(false);
                            blankfields();
                            setimgdisplay("none");
                            setcoverdisplay("none");
                            setviddisplay("none");
                            setzipdisplay("none");
                            setclsstyle("none");
                            setvidlbl("");
                            setimgPreview("");
                            setziplbl("");
                            setvidstyle("none");
                            setzipstyle("none");
                          });
                      }
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <>
                        <Form>
                          <div className="row m-0 pb-3 mb-3 pt-3  ">
                            <div className="col-12 pe-0">
                              <div className="d-flex imageDropBoxDashboardProfessional align-items-center">
                                <button
                                  className="w-100"
                                  type="button"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <BsPlusLg
                                    style={{
                                      color: "#fff",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <span className="ps-2">Upload Zip</span>
                                </button>
                                <p className="ps-4"> </p>
                                <input
                                  className="pointer"
                                  type="file"
                                  accept=".zip,.rar,.7zip"
                                  name="project"
                                  onChange={(e) => {
                                    let sizeOfFile = e.target.files[0].size;

                                    if (sizeOfFile > 500e6) {
                                      setFieldValue(null);
                                      setziplbl(null);

                                      setzipstyle("none");

                                      let msg =
                                        "Zip size should be less then or equal to  500MB";
                                      setMessageZip(msg);
                                    } else {
                                      setMessageZip(null);

                                      setFieldValue(
                                        "project",
                                        e.target.files[0]
                                      );
                                      let name = e.target.files[0].name;
                                      let maxLength = 20;
                                      const trimmedFileName =
                                        name.length > maxLength
                                          ? name.slice(0, maxLength) +
                                            "..." +
                                            name.slice(-4)
                                          : name;

                                      setziplbl(trimmedFileName);
                                      setzipstyle("block");
                                      setzipclear(e);
                                      setzipdisplay("none");
                                    }
                                  }}
                                />
                                <div
                                  className={zipstyle}
                                  style={{ marginTop: "2%" }}
                                >
                                  <span>{ziplbl}</span>
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10%",
                                      bottom: "10%",
                                    }}
                                  >
                                    <GiCancel
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      size={25}
                                      color="gray"
                                      onClick={() => {
                                        setzipstyle("none");
                                        setziplbl("");
                                        zipnull(zipclear);
                                        setFieldValue("project", null);
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <p className="text-danger">{messageZip}</p>

                              <span
                                style={{ color: "red" }}
                                className={zipdisplay}
                              >
                                Zip required
                              </span>
                            </div>
                          </div>
                          {uploaded > 0 && (
                            <div className="progress-file-upload mb-5">
                              <div className="progress ">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${uploaded}%` }}
                                  aria-valuenow={uploaded}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >{`${uploaded}%`}</div>
                              </div>
                            </div>
                          )}
                          {uploaded == 100 && (
                            <div className="my-1">
                              <p className="text-center">Keep waiting...</p>
                            </div>
                          )}
                          <div className="row pb-5">
                            <div className="d-flex justify-content-center">
                              <button
                                disabled={disbutton ? true : false}
                                type="submit"
                                className="ModalCategorySubmit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>
              {/* Model for out of storage */}

              <Modal
                show={showStorage}
                onHide={handleStoreClose}
                backdrop="static"
                centered
              >
                <Modal.Body>
                  Your storage limit is full. Upgrade your plan to get more
                  storage.
                </Modal.Body>
                <Modal.Footer>
                  <Button
                    variant="primary"
                    onClick={handleStoreClose}
                    style={{ background: "#01a78a", border: "0" }}
                  >
                    Ok
                  </Button>
                </Modal.Footer>
              </Modal>

              {/* design upload modal */}

              {/* loader */}
              <Modal
                show={loader}
                animation={false}
                fullscreen={true}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalProfessionalDashboard"
              >
                <Loader />
              </Modal>
              {/* loader */}

              {/* preview data */}

              <>
                <Modal
                  show={state?.preview_data_modal}
                  // fullscreen={
                  //   state?.preview_catagory_data?.type === "edit" ? false : false
                  // }
                  size={
                    state?.preview_catagory_data?.type === "image" ||
                    state?.preview_catagory_data?.type === "video"
                      ? "xl"
                      : "lg"
                  }
                  aria-labelledby="contained-modal-title-vcenter"
                  centered
                  className="modalProfessionalDashboard"
                >
                  {state?.preview_catagory_data?.type === "edit" ? (
                    ""
                  ) : (
                    <button
                      className="modal-closebtn"
                      onClick={() => {
                        dispatch({ type: "PREVIEW_DATA_MODAL", value: false });
                      }}
                    >
                      <IoMdClose style={{ color: "#fff" }} />
                    </button>
                  )}
                  {state?.preview_catagory_data &&
                    state?.preview_catagory_data?.type === "image" && (
                      <>
                        <div className="multiPreview_image">
                          <Carousel>
                            {state?.preview_catagory_data?.image.map((res) => (
                              <>
                                <img src={res} alt="image" />
                              </>
                            ))}
                          </Carousel>
                        </div>
                      </>
                    )}

                  {state?.preview_catagory_data &&
                    state?.preview_catagory_data?.type === "edit" && (
                      <Formik
                        initialValues={{
                          ...cookies?.user_data,
                          price:
                            state?.preview_catagory_designs?.data?.final_data[
                              state?.preview_catagory_data.index
                            ].price,
                          image:
                            state?.preview_catagory_designs?.data?.final_data[
                              state?.preview_catagory_data.index
                            ].image[0],
                          cover_image:
                            state?.preview_catagory_designs?.data?.final_data[
                              state?.preview_catagory_data.index
                            ].cover_image,
                          video: "",
                          project: "",
                          customize_price:
                            state?.preview_catagory_designs?.data?.final_data[
                              state?.preview_catagory_data.index
                            ].customize_price,
                        }}
                        validationSchema={SetUpSchema}
                        onSubmit={(values, { setSubmitting }) => {
                          if (imgLengthUpdt > 10) {
                            setImgmaxUp(true);
                            return false;
                          } else {
                            const catagoryUpload = new FormData();
                            catagoryUpload.append(
                              "user_id",
                              cookies?.user_data?.user_id
                            );
                            catagoryUpload.append(
                              "user_token",
                              cookies?.user_data?.user_token
                            );
                            catagoryUpload.append(
                              "role",
                              cookies?.user_data?.role
                            );
                            catagoryUpload.append(
                              "sub_category_id",
                              modalSubCatagoryID
                            );
                            catagoryUpload.append("category_id", "3");

                            values?.image.length &&
                              Object.values(values?.image).map((editimage) => {
                                catagoryUpload.append("image", editimage);
                              });

                            catagoryUpload.append("price", values?.price);
                            catagoryUpload.append(
                              "project_id",
                              state?.preview_catagory_designs?.data?.final_data[
                                state?.preview_catagory_data.index
                              ]?.id
                            );

                            catagoryUpload.append(
                              "customize_price",
                              values?.customize_price
                            );
                            catagoryUpload.append(
                              "cover_image",
                              values?.cover_image
                            );

                            handleEditDesign(catagoryUpload);
                          }
                        }}
                      >
                        {({ values, setFieldValue }) => (
                          <>
                            <Form className="p-5">
                              <h3 className="theme-text-color fw-bolder mb-4 text-center">
                                Edit Design
                              </h3>
                              <div className="row">
                                <div className="col-lg-6 col-12">
                                  <div className="selectprice">
                                    <BsCurrencyDollar />
                                    <Field
                                      type="number"
                                      placeholder="Enter Your project Price "
                                      className="priceInput"
                                      onKeyDown={handleKeyDownPrice}
                                      name="price"
                                      value={values.price}
                                      onChange={(e) => {
                                        setFieldValue("price", e.target.value);
                                      }}
                                    />
                                    <ErrorMessage
                                      name="price"
                                      component="div"
                                      className="m-2 text-danger"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-lg-6 col-12"
                                  style={{ height: "56px" }}
                                >
                                  <div
                                    className="selectCategoryMain h-100 d-flex align-items-center "
                                    style={{
                                      border: "1px solid rgb(118, 118, 118)",
                                      borderRadius: "5px",
                                      position: "relative",
                                    }}
                                  >
                                    <BiCategory />
                                    <p className="m-0">Update Cover Image</p>

                                    <>
                                      <div
                                        className={coverstyle}
                                        style={{
                                          width: "70px",

                                          marginLeft: "18%",
                                        }}
                                      >
                                        <img
                                          style={{
                                            height: "44px",
                                            maxWidth: "50px",
                                          }}
                                          src={
                                            coverPreview
                                              ? coverPreview
                                              : state?.preview_catagory_designs
                                                  ?.data?.final_data[
                                                  state?.preview_catagory_data
                                                    .index
                                                ].cover_image
                                          }
                                          alt="preview"
                                        />
                                        <span
                                          style={{
                                            position: "absolute",
                                            right: "33%",
                                            top: "1%",
                                          }}
                                        >
                                          <GiCancel
                                            onClick={() => {
                                              setcoverstyle("none");
                                            }}
                                          />
                                        </span>
                                      </div>
                                    </>

                                    <input
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg,image/jpg"
                                      className="inputImageFeaturedImage"
                                      onChange={(e) => {
                                        setFieldValue(
                                          "cover_image",
                                          e.target.files[0]
                                        );
                                        setcoverPreview(
                                          URL.createObjectURL(e.target.files[0])
                                        );
                                        setcoverstyle("block");
                                      }}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-lg-6 mt-5 mb-3">
                                  <div className="selectprice">
                                    <BsCurrencyDollar />
                                    <Field
                                      type="number"
                                      placeholder="Enter Your Customization Price in Sq m "
                                      className="priceInput"
                                      name="customize_price"
                                      onKeyDown={handleKeyDownPrice}
                                      value={values.customize_price}
                                      onChange={(e) => {
                                        setFieldValue(
                                          "customize_price",
                                          e.target.value
                                        );
                                      }}
                                    />
                                    <ErrorMessage
                                      name="customize_price"
                                      component="div"
                                      className="m-2 text-danger"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-lg-6 mt-5 featuredImageO"
                                  style={{ height: "56px" }}
                                >
                                  <div
                                    className="selectCategoryMain h-100 d-flex align-items-center "
                                    style={{
                                      border: "1px solid rgb(118, 118, 118)",
                                      borderRadius: "5px",
                                      position: "relative",
                                    }}
                                  >
                                    <BiCategory />
                                    <p className="m-0">
                                      Update Featured Image to Display
                                    </p>

                                    {!imgVald ? (
                                      <>
                                        <div
                                          className={clsstyle}
                                          style={{
                                            width: "70px",

                                            marginLeft: "10%",
                                          }}
                                        >
                                          <img
                                            style={{
                                              height: "44px",
                                              maxWidth: "50px",
                                            }}
                                            src={
                                              imgPreview
                                                ? imgPreview
                                                : state
                                                    ?.preview_catagory_designs
                                                    ?.data?.final_data[
                                                    state?.preview_catagory_data
                                                      .index
                                                  ].image[0]
                                            }
                                            alt="preview"
                                          />
                                          <span
                                            style={{
                                              position: "absolute",
                                              right: "33%",
                                              top: "1%",
                                            }}
                                          >
                                            <GiCancel
                                              onClick={() => {
                                                setclsstyle("none");
                                              }}
                                            />
                                          </span>
                                        </div>
                                      </>
                                    ) : null}

                                    <input
                                      type="file"
                                      accept="image/png, image/gif, image/jpeg,image/jpg"
                                      className="inputImageFeaturedImage"
                                      onChange={(e) => {
                                        setimgPreview(
                                          URL.createObjectURL(e.target.files[0])
                                        );
                                        const selectImgL =
                                          e.target.files.length;

                                        setImgLengthUpdt(selectImgL);

                                        if (selectImgL > 10) {
                                          setImgVald(true);
                                          setclsstyle("none");
                                          setImgmaxUp(true);
                                        } else {
                                          setFieldValue(
                                            "image",
                                            e.target.files
                                          );
                                          setImgVald(false);
                                          setImgmaxUp(false);
                                        }
                                        setclsstyle("block");
                                      }}
                                      multiple
                                    />
                                  </div>
                                  {imgmaxUp ? (
                                    <>
                                      {" "}
                                      <span
                                        style={{
                                          color: "red",
                                          paddingTop: "7px",
                                        }}
                                      >
                                        You can select up to 10 images
                                      </span>
                                    </>
                                  ) : null}
                                </div>
                              </div>

                              <div className="row m-0 pb-3 mb-3 editPurchaseDesign">
                                <div className="col-xl col-12 ps-0 p-0">
                                  <div className="d-flex imageDropBoxDashboardProfessional align-items-center">
                                    <button
                                      disabled={disbutton ? true : false}
                                      className="w-100"
                                      type="button"
                                      onClick={() => {
                                        dispatch({
                                          type: "PREVIEW_DATA_MODAL",
                                          value: false,
                                        });
                                        dispatch({
                                          type: "EDIT_DESIGNS_MODAL_VIDEO",
                                          value: true,
                                        });
                                        setImgmaxUp(false);
                                      }}
                                    >
                                      <span className="ps-2">Update Video</span>
                                    </button>
                                  </div>
                                </div>
                                <div className="col-xl col-12 ps-0 p-0">
                                  <div className="d-flex imageDropBoxDashboardProfessional align-items-center">
                                    <button
                                      disabled={disbutton ? true : false}
                                      className="w-100"
                                      type="button"
                                      onClick={() => {
                                        dispatch({
                                          type: "PREVIEW_DATA_MODAL",
                                          value: false,
                                        });
                                        dispatch({
                                          type: "EDIT_DESIGNS_MODAL_ZIP",
                                          value: true,
                                        });
                                      }}
                                    >
                                      <span className="ps-2">Update Zip</span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                              {uploaded > 0 && (
                                <div className="progress-file-upload my-3">
                                  <div className="progress ">
                                    <div
                                      className="progress-bar"
                                      role="progressbar"
                                      style={{ width: `${uploaded}%` }}
                                      aria-valuenow={uploaded}
                                      aria-valuemin="0"
                                      aria-valuemax="100"
                                    >{`${uploaded}%`}</div>
                                  </div>
                                </div>
                              )}
                              <div className="row pb-5 justify-content-center">
                                <button
                                  disabled={disbutton ? true : false}
                                  type="submit"
                                  className="ModalCategorySubmit mx-2"
                                >
                                  Submit
                                </button>
                                <button
                                  disabled={disbutton ? true : false}
                                  type="button"
                                  onClick={() => {
                                    dispatch({
                                      type: "PREVIEW_DATA_MODAL",
                                      value: false,
                                    });
                                    blankfields();
                                    setImgVald(false);
                                    setImgmaxUp(false);
                                    subCatData();
                                  }}
                                  style={{ border: "1px solid" }}
                                  className="ModalCategorySubmit mx-2 bg-white theme-text-color"
                                >
                                  Back
                                </button>
                              </div>
                            </Form>
                          </>
                        )}
                      </Formik>
                    )}
                  {state?.preview_catagory_data &&
                    state?.preview_catagory_data?.type === "video" &&
                    state?.preview_catagory_data?.video !== "" &&
                    state?.preview_catagory_data?.video && (
                      <video
                        className="h-100"
                        src={
                          state?.preview_catagory_data?.video &&
                          state?.preview_catagory_data?.video
                        }
                        controls="true"
                        autoplay="true"
                        style={{ maxHeight: "532px" }}
                      ></video>
                    )}
                  {state?.preview_catagory_data &&
                    state?.preview_catagory_data?.type === "zip" && (
                      <h1>zip</h1>
                    )}
                </Modal>
              </>

              {/* edit video kro */}

              <Modal
                show={state?.edit_design_modal_video}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalProfessionalDashboard"
              >
                <Modal.Header>
                  <button
                    disabled={disbutton ? true : false}
                    className="border-0"
                    style={{ margin: "0 0 0 auto", background: "none" }}
                    onClick={() => {
                      blankfields();
                      setvidstyle("none");
                      setviddisplay("none");
                      setImgmaxUp(false);
                      setImgVald(false);
                      dispatch({
                        type: "EDIT_DESIGNS_MODAL_VIDEO",
                        value: false,
                      });
                    }}
                  >
                    <GrClose size={25} />
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <h4>Update Your Video.</h4>
                  <Formik
                    initialValues={{
                      ...cookies?.user_data,

                      video: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (!values?.video) {
                        setviddisplay("block");
                      } else {
                        const catagoryUpload = new FormData();
                        catagoryUpload.append(
                          "user_id",
                          cookies?.user_data?.user_id
                        );
                        catagoryUpload.append(
                          "user_token",
                          cookies?.user_data?.user_token
                        );
                        catagoryUpload.append("role", cookies?.user_data?.role);
                        catagoryUpload.append(
                          "sub_category_id",
                          modalSubCatagoryID
                        );
                        catagoryUpload.append("category_id", "3");
                        catagoryUpload.append(
                          "project_id",
                          state?.preview_catagory_designs?.data?.final_data[
                            state?.preview_catagory_data.index
                          ]?.id
                        );

                        catagoryUpload.append("video", values?.video);
                        setDisbutton(true);
                        // setLoader(true);
                        axios
                          .post(
                            `${BaseUrl}/professional/sell_design/video/`,
                            catagoryUpload,
                            {
                              onUploadProgress: (data) => {
                                setUploaded(
                                  Math.round((data.loaded / data.total) * 100)
                                );
                              },
                            }
                          )
                          .then((res) => {
                            if (res?.data?.status === "Success") {
                              subCatData();
                              setImgmaxUp(false);
                              subCatData();
                              setImgVald(false);
                              subCatData();
                              setProjectId(projectId);
                              dispatch({
                                type: "EDIT_DESIGNS_MODAL_VIDEO",
                                value: false,
                              });
                              setDisbutton(false);
                              setLoader(false);
                              blankfields();
                              setviddisplay("none");
                              setzipdisplay("none");
                              setclsstyle("none");
                              setvidlbl("");
                              setimgPreview("");
                              setziplbl("");
                              setvidstyle("none");
                              setzipstyle("none");
                              setUploaded(null);
                            } else if (
                              res?.data?.status === "Failed" &&
                              res?.data?.error_code == 108
                            ) {
                              setUploaded(null);
                              setDisbutton(false);
                              // setLoader(false);
                              blankfields();
                              handleStorageShow();
                            } else {
                              dispatch({
                                type: "UPLOAD_DESIGNS_MODAL_VIDEO",
                                value: true,
                              });
                              setUploaded(null);
                              // setLoader(false);
                              setDisbutton(false);
                            }
                          })
                          .catch((error) => {
                            toast.error("Network  Error!");
                            setUploaded(null);
                            setDisbutton(false);
                            // setLoader(false);
                            blankfields();
                            dispatch({
                              type: "EDIT_DESIGNS_MODAL_VIDEO",
                              value: false,
                            });
                          });
                      }
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <>
                        <Form>
                          <div className="row m-0 pb-3 mb-3   ">
                            <div className="col-12 ps-0">
                              <div className="d-flex imageDropBoxDashboardProfessional align-items-center">
                                <button
                                  className="w-100 pointer"
                                  type="button"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <BsPlusLg
                                    className="pointer"
                                    style={{
                                      color: "#fff",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <span className="ps-2 cursor-pointer">
                                    Upload Video
                                  </span>
                                </button>

                                <div
                                  className={vidstyle}
                                  style={{ marginTop: "2%", cursor: "pointer" }}
                                >
                                  <span>
                                    {vidlbl ? (
                                      vidlbl
                                    ) : state?.preview_catagory_designs?.data
                                        ?.final_data[
                                        state?.preview_catagory_data.index
                                      ]?.video === "" ? (
                                      <>You do not have video</>
                                    ) : (
                                      state?.preview_catagory_designs?.data?.final_data[
                                        state?.preview_catagory_data.index
                                      ]?.video.slice(
                                        state?.preview_catagory_designs?.data?.final_data[
                                          state?.preview_catagory_data.index
                                        ]?.video.lastIndexOf("/") + 1,
                                        state?.preview_catagory_designs?.data?.final_data[
                                          state?.preview_catagory_data.index
                                        ]?.video.lastIndexOf("/") + 21
                                      )
                                    )}
                                  </span>

                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10%",
                                      bottom: "10%",
                                    }}
                                  >
                                    <GiCancel
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      size={25}
                                      color="gray"
                                      onClick={() => {
                                        setvidstyle("none");
                                        setvidlbl("");
                                        vidnull(vidclear);
                                        setFieldValue("video", null);
                                      }}
                                    />
                                  </span>
                                </div>
                                <p className="ps-4 "></p>
                                <input
                                  type="file"
                                  accept="video/*"
                                  name="project"
                                  className="pointer"
                                  onChange={(e) => {
                                    let sizeOfFile = e.target.files[0].size;

                                    if (sizeOfFile > 500e6) {
                                      setFieldValue(null);
                                      setvidlbl(null);
                                      setvidstyle("none");
                                      let msg =
                                        "Video size should be less then or equal to  500MB";
                                      setMessage(msg);
                                    } else {
                                      setMessage(null);
                                      setFieldValue("video", e.target.files[0]);
                                      setvidclear(e);
                                      let name = e.target.files[0].name;
                                      const maxLength = 20;
                                      const trimmedFileName =
                                        name.length > maxLength
                                          ? name.slice(0, maxLength) +
                                            "..." +
                                            name.slice(-4)
                                          : name;
                                      setvidlbl(trimmedFileName);
                                      setvidstyle("block");
                                      setviddisplay("none");
                                    }
                                  }}
                                />
                              </div>
                              <p className="text-danger">{message}</p>
                              <span className={`${viddisplay} text-danger`}>
                                Update video
                              </span>
                            </div>
                          </div>
                          {uploaded > 0 && (
                            <div className="progress-file-upload my-4">
                              <div className="progress ">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${uploaded}%` }}
                                  aria-valuenow={uploaded}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >{`${uploaded}%`}</div>
                              </div>
                            </div>
                          )}
                          {uploaded == 100 && (
                            <div className="my-1">
                              <p className="text-center">Keep waiting...</p>
                            </div>
                          )}
                          <div className="videoForbuy pb-5">
                            <div>
                              <button
                                disabled={disbutton ? true : false}
                                type="submit"
                                className="ModalCategorySubmit"
                              >
                                Upload
                              </button>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>

              {/* edit zip kro */}
              <Modal
                show={state?.edit_design_modal_zip}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="modalProfessionalDashboard"
              >
                <Modal.Header>
                  <button
                    disabled={disbutton ? true : false}
                    style={{ margin: "0 0 0 auto", background: "none" }}
                    onClick={() => {
                      blankfields();
                      setImgmaxUp(false);
                      setImgVald(false);
                      setzipstyle("none");
                      setzipdisplay("none");
                      dispatch({
                        type: "EDIT_DESIGNS_MODAL_ZIP",
                        value: false,
                      });
                    }}
                    className="border-0"
                  >
                    <GrClose size={25} />
                  </button>
                </Modal.Header>
                <Modal.Body>
                  <h4>Update Your Project.</h4>
                  <Formik
                    initialValues={{
                      ...cookies?.user_data,

                      project: "",
                    }}
                    onSubmit={(values, { setSubmitting }) => {
                      if (!values?.project) {
                        setzipdisplay("block");
                        return false;
                      } else {
                        const catagoryUpload = new FormData();
                        catagoryUpload.append(
                          "user_id",
                          cookies?.user_data?.user_id
                        );
                        catagoryUpload.append(
                          "user_token",
                          cookies?.user_data?.user_token
                        );
                        catagoryUpload.append("role", cookies?.user_data?.role);
                        catagoryUpload.append(
                          "sub_category_id",
                          modalSubCatagoryID
                        );
                        catagoryUpload.append("category_id", "3");
                        catagoryUpload.append(
                          "project_id",
                          state?.preview_catagory_designs?.data?.final_data[
                            state?.preview_catagory_data.index
                          ]?.id
                        );
                        catagoryUpload.append("project", values?.project);
                        setDisbutton(true);
                        // setLoader(true);
                        axios
                          .post(
                            `${BaseUrl}/professional/sell_design/project/`,
                            catagoryUpload,
                            {
                              onUploadProgress: (data) => {
                                setUploaded(
                                  Math.round((data.loaded / data.total) * 100)
                                );
                              },
                            }
                          )
                          .then((res) => {
                            if (res?.data?.status === "Success") {
                              subCatData();
                              setImgmaxUp(false);
                              setImgVald(false);
                              setProjectId(res?.data?.data?.project_id);
                              dispatch({
                                type: "EDIT_DESIGNS_MODAL_ZIP",
                                value: false,
                              });
                              setUploaded(null);
                              setDisbutton(false);
                              // setLoader(false);
                              blankfields();
                              setzipdisplay("none");
                              setziplbl("");

                              setzipstyle("none");
                            } else if (
                              res?.data?.status === "Failed" &&
                              res?.data?.error_code == 108
                            ) {
                              // setLoader(false);
                              setUploaded(null);
                              setDisbutton(false);
                              blankfields();
                              handleStorageShow();
                            } else {
                              dispatch({
                                type: "EDIT_DESIGNS_MODAL_ZIP",
                                value: true,
                              });
                              setUploaded(null);
                              setDisbutton(false);
                              setImgmaxUp(false);
                              setImgVald(false);
                              setLoader(false);
                            }
                          })
                          .catch((error) => {
                            toast.error("Network  Error!");
                            setUploaded(null);
                            setDisbutton(false);
                            setImgmaxUp(false);
                            setImgVald(false);
                            setLoader(false);
                            setImgmaxUp(false);
                            setImgVald(false);
                            setzipstyle("none");
                            setzipdisplay("none");
                            dispatch({
                              type: "EDIT_DESIGNS_MODAL_ZIP",
                              value: false,
                            });
                          });
                      }
                    }}
                  >
                    {({ isSubmitting, setFieldValue }) => (
                      <>
                        <Form>
                          <div className="row m-0 pb-3 mb-3 pt-3  ">
                            <div className="col-12 pe-0">
                              <div className="d-flex imageDropBoxDashboardProfessional align-items-center">
                                <button
                                  className="w-100"
                                  type="button"
                                  style={{ pointerEvents: "none" }}
                                >
                                  <BsPlusLg
                                    style={{
                                      color: "#fff",
                                      fontSize: "17px",
                                    }}
                                  />
                                  <span className="ps-2">Upload Zip</span>
                                </button>
                                <p className="ps-4"> </p>
                                <input
                                  className="pointer"
                                  type="file"
                                  accept=".zip,.rar,.7zip"
                                  name="project"
                                  onChange={(e) => {
                                    let sizeOfFile = e.target.files[0].size;
                                    if (sizeOfFile > 500e6) {
                                      setFieldValue(null);
                                      setziplbl(null);
                                      setzipstyle("none");
                                      let msg =
                                        "Zip size should be less then or equal to  500MB";
                                      setMessageZip(msg);
                                    } else {
                                      setMessageZip(null);
                                      setFieldValue(
                                        "project",
                                        e.target.files[0]
                                      );
                                      let name = e.target.files[0].name;
                                      let maxLength = 20;
                                      const trimmedFileName =
                                        name.length > maxLength
                                          ? name.slice(0, maxLength) +
                                            "..." +
                                            name.slice(-4)
                                          : name;

                                      setziplbl(trimmedFileName);
                                      setzipstyle("block");
                                      setzipclear(e);
                                      setzipdisplay("none");
                                    }
                                  }}
                                />
                                <div
                                  className={zipstyle}
                                  style={{ marginTop: "2%" }}
                                >
                                  <span>
                                    {ziplbl ? (
                                      ziplbl
                                    ) : state?.preview_catagory_designs?.data
                                        ?.final_data[
                                        state?.preview_catagory_data.index
                                      ]?.project === "" ? (
                                      <>You do not have Project</>
                                    ) : (
                                      state?.preview_catagory_designs?.data?.final_data[
                                        state?.preview_catagory_data.index
                                      ]?.project.slice(
                                        state?.preview_catagory_designs?.data?.final_data[
                                          state?.preview_catagory_data.index
                                        ]?.project.lastIndexOf("/") + 1,
                                        state?.preview_catagory_designs?.data?.final_data[
                                          state?.preview_catagory_data.index
                                        ]?.project.lastIndexOf("/") + 21
                                      )
                                    )}
                                  </span>
                                  <span
                                    style={{
                                      position: "absolute",
                                      right: "10%",
                                      bottom: "10%",
                                    }}
                                  >
                                    <GiCancel
                                      style={{
                                        cursor: "pointer",
                                      }}
                                      size={25}
                                      color="gray"
                                      onClick={() => {
                                        setzipstyle("none");
                                        setziplbl("");
                                        zipnull(zipclear);
                                        setFieldValue("project", null);
                                      }}
                                    />
                                  </span>
                                </div>
                              </div>
                              <p className="text-danger">{messageZip}</p>

                              <span
                                style={{ color: "red" }}
                                className={zipdisplay}
                              >
                                Zip required
                              </span>
                            </div>
                          </div>
                          {uploaded > 0 && (
                            <div className="progress-file-upload my-4">
                              <div className="progress ">
                                <div
                                  className="progress-bar"
                                  role="progressbar"
                                  style={{ width: `${uploaded}%` }}
                                  aria-valuenow={uploaded}
                                  aria-valuemin="0"
                                  aria-valuemax="100"
                                >{`${uploaded}%`}</div>
                              </div>
                            </div>
                          )}
                          {uploaded == 100 && (
                            <div className="my-1">
                              <p className="text-center">Keep waiting...</p>
                            </div>
                          )}
                          <div className="row pb-5">
                            <div className="d-flex justify-content-center">
                              <button
                                disabled={disbutton ? true : false}
                                type="submit"
                                className="ModalCategorySubmit"
                              >
                                Submit
                              </button>
                            </div>
                          </div>
                        </Form>
                      </>
                    )}
                  </Formik>
                </Modal.Body>
              </Modal>

              {/* preview data */}

              {/* Pagination Data */}

              {/* preview data */}
            </div>
          </main>
          <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
        </div>
      </>
    );
  } else {
    return <Loader />;
  }
};

export default ProfessionalBuyAndSale;
