import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { HeaderDashboard } from "../../Header";
import Dashboardside from "../../ProfessionalDashboardside";
import { useCookies } from "react-cookie";
import Select from "react-select";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Button, Modal } from "react-bootstrap";
import ReactLotti from "../../../loader/ReactLotti";
import ReactLotti2 from "../../../loader/ReactLottie3";
import { BaseUrl } from "../../../BaseUrl";
import { MdDone } from "react-icons/md";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import ReactLotti3 from "../../../loader/ReactLottie3";

const SubscriptionPlane = () => {
  const stripe = useStripe();
  const elements = useElements();
  const navigate = useNavigate();
  const [cookies] = useCookies();

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  });
  const handleKeyPress = (event) => {
    const keyCode = event.keyCode || event.which;
    const keyValue = String.fromCharCode(keyCode);
    const pattern = /^[0-9]+$/;

    if (!pattern.test(keyValue)) {
      event.preventDefault();
    }
  };
  const [showPlan, setShowPlan] = useState(false);

  const planClose = () => setShowPlan(false);
  const planShow = () => setShowPlan(true);

  // const [plans, setPlans] = useState();
  const [loading, setLoading] = useState(false);
  const [currentPlans, setCurrentPlans] = useState(1);
  const [isError, setIsError] = useState(false);
  const [payErrorMessage, setPayErrorMessage] = useState("");
  const subscription = {
    display: "flex",
  };
  const [noPlans, setNoPlans] = useState(false);
  const [plansList, setPlansList] = useState([]);
  const [typePlans, setTypePlans] = useState("monthly");
  const [isPayment, setIsPayment] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [show, setShow] = useState(false);
  const [carderr, setCarderr] = useState(false);

  const [curCart, setCurCart] = useState({});
  const [invoice, setInvoice] = useState();
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [payerr, setpayerr] = useState("");

  const buyPlan = () => {
    if (cookies?.user_data) {
      if (cookies?.user_data?.category_selected) {
        if (cookies?.user_data.role === "professional") {
          axios
            .post(
              `${BaseUrl}/identity/get_dashboard_profile/`,
              cookies?.user_data
            )
            .then((res) => {
              const { subscription_plan_id, is_cancel } = res?.data?.data;
              setCurrentPlans(subscription_plan_id);
              setNoPlans(is_cancel);
              axios
                .get(`${BaseUrl}/stripe/subscription-plans/`)
                .then((responce) => {
                  setLoading(true);
                  const { final_list } = responce?.data?.data;
                  const list = final_list.filter((item) => {
                    if (item?.plan_type === typePlans) {
                      return item;
                    }
                  });
                  setPlansList(list);
                })
                .catch((err) => {
                  setLoading(true);
                });
            });
        } else {
          navigate("/clientdashboard");
        }
      } else {
        if (cookies?.user_data.role === "professional") {
          navigate("/categoryArchitecture");
        } else {
          navigate("/client-architechture");
        }
      }
    } else {
      navigate("/select-sign-in");
    }
  };

  useEffect(() => {
    buyPlan();
  }, [typePlans]);

  // Subscript
  const months = [
    { value: "01", label: "01", name: "exp_month" },
    { value: "02", label: "02", name: "exp_month" },
    { value: "03", label: "03", name: "exp_month" },
    { value: "04", label: "04", name: "exp_month" },
    { value: "05", label: "05", name: "exp_month" },
    { value: "06", label: "06", name: "exp_month" },
    { value: "07", label: "07", name: "exp_month" },
    { value: "08", label: "08", name: "exp_month" },
    { value: "09", label: "09", name: "exp_month" },
    { value: "10", label: "10", name: "exp_month" },
    { value: "11", label: "11", name: "exp_month" },
    { value: "12", label: "12", name: "exp_month" },
  ];
  const d = new Date();
  let year = d.getFullYear();
  let years = [];
  for (let i = 0; i < 20; ++i) {
    years.push({
      value: `${year + i}`,
      label: `${year + i}`,
      name: "exp_year",
    });
  }
  const [cartInfo, setCartInfo] = useState({
    card_number: "",
    exp_month: "",
    exp_year: "",
    cvc: "",
  });

  const handalChange = (name, value) => {
    setCartInfo({
      ...cartInfo,
      [name]: value,
    });
  };
  const [planIdres, setPlanIdRes] = useState();
  const handlePayment = (payload) => {
    setPlanIdRes(payload?.id);
    axios
      .post(`${BaseUrl}/stripe/all_card_subscription/`, {
        professional_id: cookies?.user_data?.user_id,
        professioanl_token: cookies?.user_data?.user_token,
        plan_id: payload?.id,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          if (res?.data?.data?.cards.length === 0) {
            setCurCart(payload);
            setIsPayment(true);
          } else {
            navigate("/subscription-checkout", {
              state: {
                professional_data: res?.data?.data,
                plan_id: payload?.id,
              },
            });
          }
        }
      })
      .catch((err) => {});
  };

  const SaveCard = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    // Api for generating client secret
    const { data } = await axios.post(
      `${BaseUrl}/stripe/professional_card_subscription/`,
      {
        professioanl_id: cookies?.user_data?.user_id,
        professioanl_token: cookies?.user_data?.user_token,
      }
    );
    const client_secret = data.client_secret_id;

    const paymentResult = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });

    if (paymentResult.error) {
      setpayerr(paymentResult.error.message);
      setPaymentLoading(false);
    } else {
      if (paymentResult.setupIntent.status === "succeeded") {
        axios
          .post(`${BaseUrl}/stripe/all_card_subscription/`, {
            professional_id: cookies?.user_data?.user_id,
            professioanl_token: cookies?.user_data?.user_token,
            plan_id: planIdres,
          })
          .then((res) => {
            if (res?.data?.status === "Success") {
              if (res?.data?.data?.cards.length === 0) {
                setIsPayment(true);
              } else {
                axios
                  .post(`${BaseUrl}/stripe/pro_card_success_mail/`, {
                    professioanl_id: cookies?.user_data?.user_id,
                    payment_method_id:
                      paymentResult?.setupIntent?.payment_method,
                    professioanl_token: cookies?.user_data?.user_token,
                  })
                  .then((resp) => {
                    setIsPayment(false);
                    setPaymentLoading(false);
                    navigate("/subscription-checkout", {
                      state: {
                        professional_data: res?.data?.data,
                        plan_id: planIdres,
                      },
                    });
                  })
                  .catch((err) => {});
              }
            }
          })
          .catch((err) => {});
      }
    }
  };

  const handalPurchase = (event) => {
    event.preventDefault();
    setShow(true);
    setIsPayment(false);
  };

  const handleClosePayTab = (plans) => {
    setCurrentPlans(plans);
    setShow(false);
    setCartInfo({
      card_number: "",
      exp_month: "",
      exp_year: "",
      cvc: "",
    });
  };
  const [validDetails, SetValidDetails] = useState(false);
  const checkDetails = () => {
    if (
      !cartInfo.card_number ||
      !cartInfo.cvc ||
      !cartInfo.exp_month ||
      !cartInfo.exp_year
    ) {
      setCarderr(true);
      SetValidDetails(false);
    } else {
      SetValidDetails(true);
    }
  };
  const [loading_sub, setLoading_sub] = useState(false);
  const handalSubmit = () => {
    setLoading_sub(true);
    setCarderr(false);
    const data = {
      professioanl_id: cookies?.user_data?.user_id,
      professioanl_token: cookies?.user_data?.user_token,
      ...cartInfo,
      plan_id: curCart?.id,
    };
    axios.post(`${BaseUrl}/stripe/subscription/`, data).then((res) => {
      setLoading(true);

      if (res?.data?.status === "Failed") {
        setLoading_sub(false);
        const error = res?.data?.message;
        if (
          res?.data?.status === "Failed" &&
          res?.data?.message === "Cannot downgrade your plan."
        ) {
          setPaymentError("You can not downgrade your plan");
        } else {
          setPaymentError(error.split(":")[1]);
        }
        // setPayErrorMessage(res?.data?.message);
        setPayErrorMessage(res?.data?.message?.split(":")[1]);
        setShow(false);
        setIsError(true);
        handleClosePayTab(currentPlans);
      } else {
        setLoading_sub(false);
        setInvoice(res?.data?.data?.invoice_url);
        buyPlan();
        setShowPlan(true);
        handleClosePayTab(curCart?.id);
      }
    });
    // }`
  };

  const [isCencel, setIsCencel] = useState(false);
  const cancel_subscription = () => setIsCencel(true);
  const handalCencel = () => {
    setLoading_sub(true);

    axios
      .put(`${BaseUrl}/stripe/subscription_detail/`, {
        professioanl_id: cookies?.user_data?.user_id,
        professioanl_token: cookies?.user_data?.user_token,
      })
      .then((res) => {
        axios
          .post(
            `${BaseUrl}/identity/get_dashboard_profile/`,
            cookies?.user_data
          )
          .then((res) => {
            const { subscription_plan_id, is_cancel } = res?.data?.data;
            setCurrentPlans(subscription_plan_id);
            setNoPlans(is_cancel);
            axios
              .get(`${BaseUrl}/stripe/subscription-plans/`)
              .then((responce) => {
                setIsCencel(false);

                const { final_list } = responce?.data?.data;
                const list = final_list.filter((item) => {
                  if (item?.plan_type === typePlans) {
                    return item;
                  }
                });
                setLoading_sub(false);

                setPlansList(list);
              });
          });
      });
  };

  document.querySelectorAll('input[type="number"]').forEach((input) => {
    input.oninput = () => {
      if (input.value.length > input.maxLength)
        input.value = input.value.slice(0, input.maxLength);
    };
  });

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid h-100">
          <div className="row h-100 dashboard-theme-color">
            <div className="col-xxl-2 col-md-3 col-lg-3 px-0 dashboard-theme-color">
              <Dashboardside />
            </div>
            <div className="col-xxl-10 col-md-9 col-lg-9 custom-border-radius-one  dashboard-theme-skyblue px-0 dashboard-right-section">
              <HeaderDashboard />
              {!loading ? (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={!loading}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <main className="dashboard-main">
                  <div
                    id="myactivity"
                    className="container-fluid  myProjectTable"
                  >
                    <h2 className="ps-5">Subscription Plans</h2>
                    <div className="m-0 m-md-5 shadow">
                      <div className="row mx-0">
                        <div className="col-xl-5 plan-bg">
                          <ul className="plan-box-tab d-flex mb-0 list-unstyled listSkS">
                            <li>
                              <button
                                className={
                                  typePlans === "monthly" ? "active" : ""
                                }
                                onClick={() => {
                                  setTypePlans("monthly");
                                }}
                              >
                                Monthly
                              </button>
                            </li>
                            <li>
                              <button
                                className={
                                  typePlans !== "monthly" ? "active" : ""
                                }
                                onClick={() => {
                                  setTypePlans("yearly");
                                }}
                              >
                                Annually
                              </button>
                            </li>
                          </ul>
                          {/* <p className="mb-0">*Save up to 27% when you pay annually</p> */}
                        </div>
                        <div className="col-xl-7 px-0">
                          <div className="heading border-bottom">
                            <ul className="list-unstyled pl-0 mb-0 d-flex">
                              <li
                                className="position-relative"
                                style={
                                  plansList[0]?.id === currentPlans
                                    ? {
                                        background: "#1b816e",
                                        color: "#fff",
                                      }
                                    : {}
                                }
                              >
                                {plansList[0]?.id === currentPlans ? (
                                  <MdDone
                                    style={{
                                      border: "3px solid white",
                                      color: "white",
                                      borderRadius: "50%",
                                      fontWeight: "700",
                                      fontSize: "28px",
                                      position: "absolute",
                                      top: "3px",
                                      right: "3px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                Basic
                              </li>
                              <li
                                className="position-relative"
                                style={
                                  plansList[1]?.id === currentPlans
                                    ? {
                                        background: "#1b816e",
                                        color: "#fff",
                                      }
                                    : {}
                                }
                              >
                                {plansList[1]?.id === currentPlans ? (
                                  <MdDone
                                    style={{
                                      border: "3px solid white",
                                      color: "white",
                                      borderRadius: "50%",
                                      fontWeight: "700",
                                      fontSize: "28px",
                                      position: "absolute",
                                      top: "3px",
                                      right: "3px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                Professional
                              </li>
                              <li
                                className="position-relative"
                                style={
                                  plansList[2]?.id === currentPlans
                                    ? {
                                        background: "#1b816e",
                                        color: "#fff",
                                      }
                                    : {}
                                }
                              >
                                {plansList[2]?.id === currentPlans ? (
                                  <MdDone
                                    style={{
                                      border: "3px solid white",
                                      color: "white",
                                      borderRadius: "50%",
                                      fontWeight: "700",
                                      fontSize: "28px",
                                      position: "absolute",
                                      top: "3px",
                                      right: "3px",
                                    }}
                                  />
                                ) : (
                                  ""
                                )}
                                Premium
                              </li>
                            </ul>
                          </div>
                          <div className="heading">
                            <ul className="list-unstyled pl-0 mb-0 d-flex">
                              <li
                                style={
                                  plansList[0]?.id === currentPlans
                                    ? {
                                        background: "#1b816e",
                                        color: "#fff",
                                      }
                                    : {}
                                }
                              >
                                FREE
                              </li>
                              <li
                                style={
                                  plansList[1]?.id === currentPlans
                                    ? {
                                        background: "#1b816e",
                                        color: "#fff",
                                      }
                                    : {}
                                }
                              >
                                ${plansList[1]?.amount}
                                <br /> Per&nbsp;
                                {plansList[1]?.plan_type === "monthly"
                                  ? "Month"
                                  : "Month"}
                              </li>
                              <li
                                style={
                                  plansList[2]?.id === currentPlans
                                    ? {
                                        background: "#1b816e",
                                        color: "#fff",
                                      }
                                    : {}
                                }
                              >
                                ${plansList[2]?.amount}
                                <br /> Per&nbsp;
                                {plansList[1]?.plan_type === "monthly"
                                  ? "Month"
                                  : "Month"}
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                      <div className="row fetures align-items-center mx-0">
                        <div className="col-xl-5">
                          <p>Feature</p>
                        </div>
                        <div className="col-xl-7 px-0">
                          <ul className="d-flex mb-0 list-unstyled listSkS">
                            <li>
                              {plansList[0].id === currentPlans ? (
                                <div className="d-flex">
                                  <button
                                    className="buy-now-btn-active"
                                    disabled
                                  >
                                    Free
                                  </button>
                                </div>
                              ) : (
                                <button
                                  disabled
                                  className="buy-now-btn-active"
                                  // onClick={() => {
                                  //   handlePayment(plansList[0]);
                                  // }}
                                >
                                  Free
                                </button>
                              )}
                            </li>
                            <li>
                              {plansList[1].id === currentPlans ? (
                                <div className="d-flex">
                                  {/* <button className="buy-now-btn-active">
                                    Active
                                  </button> */}
                                  {noPlans ? (
                                    <button
                                      className="buy-now-btn-cancel"
                                      style={{ marginLeft: "3px" }}
                                    >
                                      Cancelled
                                    </button>
                                  ) : (
                                    <button
                                      className="buy-now-btn-cancel"
                                      style={{ marginLeft: "3px" }}
                                      // onClick={handalCencel}
                                      onClick={cancel_subscription}
                                    >
                                      Cancel
                                    </button>
                                  )}
                                </div>
                              ) : (
                                <button
                                  className="buy-now-btn"
                                  onClick={() => {
                                    handlePayment(plansList[1]);
                                  }}
                                >
                                  Buy
                                </button>
                              )}
                            </li>
                            <li>
                              {plansList[2].id === currentPlans ? (
                                <div className="d-flex">
                                  {/* <button className="buy-now-btn-active">
                                    Active
                                  </button> */}
                                  {noPlans ? (
                                    <button
                                      className="buy-now-btn-cancel"
                                      style={{ marginLeft: "3px" }}
                                    >
                                      Cancelled
                                    </button>
                                  ) : (
                                    <button
                                      className="buy-now-btn-cancel"
                                      style={{ marginLeft: "3px" }}
                                      // onClick={handalCencel}
                                      onClick={cancel_subscription}
                                    >
                                      Cancel
                                    </button>
                                  )}
                                </div>
                              ) : plansList[1].id === currentPlans ? (
                                <button
                                  className="buy-now-btn"
                                  onClick={() => {
                                    handlePayment(plansList[2]);
                                  }}
                                >
                                  Upgrade
                                </button>
                              ) : (
                                <button
                                  className="buy-now-btn"
                                  onClick={() => {
                                    handlePayment(plansList[2]);
                                  }}
                                >
                                  Buy
                                </button>
                              )}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="row fetures2 py-3 align-items-center mx-0">
                        <div className="col-xl-5 d-flex flex-column">
                          <p>Storage</p>
                          <small>
                            User can upload their works upto specified storage
                          </small>
                        </div>
                        <div className="col-xl-7 px-0">
                          <ul className="d-flex mb-0 list-unstyled listSkS">
                            <li>
                              <p className="ms-0">{plansList[0]?.storage} MB</p>
                            </li>
                            <li>
                              <p className="ms-0">{plansList[1]?.storage} GB</p>
                            </li>
                            <li>
                              <p className="ms-0">20GB</p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="row fetures py-3 align-items-center mx-0">
                        <div className="col-xl-5 d-flex flex-column">
                          <p>Services Charges</p>
                          <small>
                            We charge a nominal fee for our services on every
                            paid invoice
                          </small>
                        </div>
                        <div className="col-xl-7 px-0">
                          <ul className="d-flex mb-0 list-unstyled listSkS">
                            <li>
                              <p className="ms-0">
                                {plansList[0]?.service_charge}%
                              </p>
                            </li>
                            <li>
                              <p className="ms-0">
                                {plansList[1]?.service_charge}%
                              </p>
                            </li>
                            <li>
                              <p className="ms-0">
                                {plansList[2]?.service_charge}%
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="row fetures2 py-3 align-items-center mx-0">
                        <div className="col-xl-5 d-flex flex-column">
                          <p>Search Boost</p>
                          <small>
                            This feature helps your profile rank higher on the
                            search results
                          </small>
                        </div>
                        <div className="col-xl-7 px-0">
                          <ul className="d-flex mb-0 list-unstyled listSkS">
                            <li>
                              <p className="ms-0">Not enable </p>
                            </li>
                            <li>
                              <p className="ms-0">
                                Enable for search results on paying extra amount
                              </p>
                            </li>
                            <li>
                              <p className="ms-0">
                                Enable for search results on paying extra amount
                              </p>
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="row fetures py-3 align-items-center mx-0">
                        <div className="col-xl-5 d-flex flex-column">
                          <p>Find Jobs</p>
                          <small>Search for jobs in your category</small>
                        </div>
                        <div className="col-xl-7 px-0">
                          <ul className="d-flex mb-0 list-unstyled listSkS">
                            <li>
                              <p className="ms-0">Enable</p>
                            </li>
                            <li>
                              <p className="ms-0">Enable</p>
                            </li>
                            <li>
                              <p className="ms-0">Enable</p>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </main>
              )}
            </div>
          </div>
        </div>

        {/* cencel plans  */}
        <Modal centered show={isCencel} onHide={() => setIsCencel(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Modal.Title>
              “if you cancel, subscription benefits will be still present and no
              refund is possible. are you sure to proceed?
            </Modal.Title>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-end">
            <Button
              variant="secondary"
              onClick={() => {
                setIsCencel(false);
              }}
            >
              Cancel
            </Button>
            <Button
              className="theme-bg-color border-0"
              onClick={handalCencel}
              disabled={loading_sub ? true : false}
            >
              {!loading_sub ? "Sure" : <ReactLotti2 />}
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal centered show={isError} onHide={() => setIsError(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Modal.Title>
              {payErrorMessage ? payErrorMessage : paymentError}
            </Modal.Title>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <Button
              className="PaymentCardSubmitButton"
              variant="secondary"
              onClick={() => {
                setPaymentError("");
                setIsError(false);
              }}
            >
              Ok
            </Button>
          </Modal.Footer>
        </Modal>

        {/*  sucessfully bought plan*/}

        <Modal centered show={showPlan} onHide={() => setShowPlan(false)}>
          <Modal.Header closeButton></Modal.Header>
          <Modal.Body>
            <Modal.Title>
              Thank you for sucessfully buying this plan!
            </Modal.Title>
          </Modal.Body>

          <Modal.Footer className="d-flex justify-content-center">
            <a
              style={{ textDecoration: "none" }}
              href={invoice}
              download={invoice}
              target="_blank"
              className="PaymentCardSubmitButton"
              variant="secondary"
              onClick={() => {
                setShowPlan(false);
              }}
            >
              Ok
            </a>
          </Modal.Footer>
        </Modal>

        {validDetails && (
          <Modal centered show={show} onHide={() => setShow(false)}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>
              <Modal.Title>
                Are you sure you want to purchase this plan
              </Modal.Title>
            </Modal.Body>

            <Modal.Footer className="d-flex justify-content-start">
              <Button
                variant="secondary"
                onClick={() => {
                  setPaymentError("");
                  setShow(false);
                }}
              >
                Cancel
              </Button>

              <Button
                disabled={loading_sub ? true : false}
                className="theme-bg-color border-0"
                onClick={handalSubmit}
              >
                {!loading_sub ? "Sure" : <ReactLotti2 />}
                {/* <ReactLotti2 /> */}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
        {/* card modal */}
        <Modal
          centered
          backdrop="static"
          keyboard={false}
          show={isPayment}
          onHide={() => {
            setIsPayment(false);
            setPaymentError("");
            setpayerr("");
            setCarderr("");
            handleClosePayTab(currentPlans);
            setPaymentLoading(false);
          }}
        >
          <Modal.Header closeButton>
            <Modal.Title></Modal.Title>
            <h4>Add Your Card details for Payment in future </h4>
          </Modal.Header>

          <Modal.Body>
            <div className="bg-white payementFormMain card-popup">
              <form onSubmit={SaveCard}>
                <div className="row m-0 pb-4 border-bottom">
                  <h6 className="card-p-0">Card Number(credit/debit)</h6>

                  <CardNumberElement
                    options={{
                      showIcon: true,
                    }}
                  />
                </div>
                <div className="row  py-3">
                  <div className="col-8">
                    <div className="row">
                      <h6>Expiry Date</h6>
                      <div className="col cardExpiry monthInput">
                        <CardExpiryElement />
                      </div>
                      <div className="col cardExpiry yearInput">
                        <CardCvcElement />
                      </div>
                    </div>
                  </div>
                  <div className="col-4">
                    <div className="row h-100">
                      <div className="col d-flex flex-column justify-content-end"></div>
                    </div>
                  </div>
                </div>
                <div style={{ color: "red" }}>{paymentError}</div>
                <div style={{ color: "red" }}>{payerr}</div>
                <div className="row">
                  <button
                    type="submit"
                    disabled={isPaymentLoading}
                    className="PaymentCardSubmitButton"
                  >
                    {isPaymentLoading ? <ReactLotti3 /> : "Save"}
                  </button>
                </div>
              </form>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};

export default React.memo(SubscriptionPlane);
