import React, { useState } from "react";
import { Header2 } from "../Header";
import styled from "styled-components";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { Button, Modal } from "react-bootstrap";
import { useCookies } from "react-cookie";
import { AiOutlineInfoCircle } from "react-icons/ai";
import { useEffect } from "react";
import ReactLotti3 from "../../loader/ReactLottie3";
import { BaseUrl } from "../../BaseUrl";
import { IoMdClose } from "react-icons/io";

const Wrapper = styled.div`
  .mileStoneDate {
    padding: 10px 10px;
    background: white;
    border-left: 1px solid #01a78a;
    text-align: center;
    width: 115px;
  }
  .update {
    padding: 10px 10px;
    background: transparent !important;
    border: none;
    border-left: 1px solid #01a78a;
    text-align: center;
    width: 115px;
    color: #01a78a;
  }
  .uploadMileStone {
    cursor: pointer;
    position: relative;
    border-radius: 0 25px 25px 0;
    padding: 11px 30px 11px;
    border: 1px solid #01a78a;
    background-color: #01a78a;
    color: white;
    span {
      z-index: 0;
    }
    input {
      position: absolute;
      z-index: 88888;
      top: 0;
      opacity: 0;
      right: 0;
      width: 100%;
      height: 100%;
    }
  }
  .buttonAndDateMain {
    display: flex;
    justify-content: end;
    position: absolute;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  .pendingMileStone {
    cursor: pointer;
    position: relative;
    border-radius: 0;
    padding: 11px 30px 11px;
    text-transform: capitalize;
    border: 0;
    background-color: transparent;
    color: #01a78a;
    border-left: 1px solid #01a78a;
  }
  .Milestone {
    position: static !important;
    border: none;
    border-left: 1px solid #01a78a;
    padding: 12px;
    width: 180px;
    text-align: center;
  }

  .decline {
    position: static !important;
    border: none;
    border-left: 1px solid #01a78a;
    padding: 12px;
    width: 60px;
    text-align: center;
    background: #abb3aa33;
  }
`;

const FromProfessionalTabPane = ({ location }) => {
  const [uploaded, setUploaded] = useState(null);
  const [imgFileLength, setImgFileLength] = useState();
  const [imgmax, setImgmax] = useState(false);
  const [submitLoader, setsubmitLoader] = useState(false);
  const [show, setShow] = useState(false);
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const [showText, setShowText] = useState(false);
  const [descshowless, setdescshowless] = useState(false);
  const [previewImages, setPreviewImages] = useState([]);
  const [previewErr, setPreviewErr] = useState("");
  const [curProject, setCurProject] = useState({});
  const [milestone, setMilestone] = useState([]);
  const [showPreviewImageModal, setshowPreviewImageModal] = useState(false);
  const [fileRangeError, setFileRangeError] = useState(false);
  const [showStorage, setShowStorage] = useState(false);
  const handleStoreClose = () => {
    setShowStorage(false);
    navigate("/subscription-plans");
  };
  const handleStorageShow = () => setShowStorage(true);
  const handleShowMore = () => {
    setShowText(true);
  };

  const handleShowLess = () => {
    setShowText(false);
  };

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  });

  const customStyleOne = {
    borderRadius: "30px",
    filter: "drop-shadow(2.5px 4.33px 6.5px rgba(0,0,0,0.2))",
    padding: "100px 0",
  };
  useEffect(() => {
    axios
      .post(`${BaseUrl}/client/particular_project_details`, {
        client_id: location?.state?.client_id,
        project_id: location?.state?.project_id,
        professional_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
      })
      .then((respo) => {
        if (respo?.data?.status === "Success") {
          setCurProject(respo?.data?.data);
          axios
            .post(`${BaseUrl}/client/particular_project_milestones`, {
              client_id: location?.state?.client_id,
              user_token: cookies?.user_data?.user_token,
              role: cookies?.user_data?.role,
              professional_id: cookies?.user_data?.user_id,
              project_id: location?.state?.project_id,
            })
            .then((res) => {
              if (res?.data?.status === "Success") {
                setMilestone(res?.data?.data);
              }
            });
        }
      });
  }, []);
  const [project, setProject] = useState();
  const [file, setFile] = useState("");
  const [error, setError] = useState("");
  const handalshow = (res) => {
    setProject(res);
    setShow(true);
  };
  const handalchage = (event) => {
    setError("");
    let sizeOfFile = event.target.files[0].size;
    if (sizeOfFile > 500e6) {
      setFileRangeError(true);
      setFile(null);
    } else {
      setFileRangeError(false);
      setFile(event.target.files);
    }
  };
  const [combined, setCombined] = useState(0);
  const [isReason, setIsReason] = useState(false);
  const [reason, setReason] = useState();
  const handalClose = () => {
    setFile("");
    setError("");
    setPreviewImages([]);
    setShow(false);
    setshowPreviewImageModal(false);
    axios
      .post(`${BaseUrl}/client/particular_project_milestones`, {
        client_id: location?.state?.client_id,
        user_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
        professional_id: cookies?.user_data?.user_id,
        project_id: location?.state?.project_id,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          setMilestone(res?.data?.data);
        }
      });
  };
  // Object.values(values?.image).map((it) => {
  // catagoryUpload.append( "image", it );
  // });

  const handleMilestoneUpdate = async (event) => {
    event.preventDefault();
    const data = new FormData();
    data.append("user_id", cookies?.user_data?.user_id);
    data.append("user_token", cookies?.user_data?.user_token);
    data.append("role", cookies?.user_data?.role);
    data.append("project_id", project?.project_id);
    data.append("milestone_id", project?.milestone_id);
    data.append("milestone_file", file[0]);
    if (file?.length > 0) {
      setsubmitLoader(true);
      await axios
        .post(`${BaseUrl}/client/milestone_file`, data, {
          onUploadProgress: (data) => {
            if (file && previewImages?.length == 0) {
              const fileProgress = Math.round((data.loaded / data.total) * 100);

              setUploaded(fileProgress);
              setCombined(fileProgress);
            } else {
              const fileProgress = Math.round((data.loaded / data.total) * 50);
              setUploaded(fileProgress);
              setCombined(fileProgress);
            }
          },
        })
        .then((res) => {
          if (res?.data?.status === "Success") {
            if (file && previewImages?.length == 0) {
              setsubmitLoader(false);
              setUploaded(null);
              handalClose();
            }
            // setshowPreviewImageModal( true );
            if (previewImages?.length > 0) {
              handleZipPreview();
            }
          } else if (
            res?.data?.status === "Failed" &&
            res?.data?.error_code == 108
          ) {
            handalClose();
            handleStorageShow();
          } else {
            setUploaded(null);
            setsubmitLoader(false);
            setError("");
          }
        })
        .catch((error) => {
          toast.error("Network  Error!");
          setsubmitLoader(false);
          setUploaded(null);
          handalClose();
        });
    } else {
      // setUploaded(null);
      // setsubmitLoader(false);
      setError("Zip file required");
    }
  };

  const handleZipPreview = async (event) => {
    const preview_data = new FormData();
    preview_data.append("user_id", cookies?.user_data?.user_id);
    preview_data.append("user_token", cookies?.user_data?.user_token);
    preview_data.append("role", cookies?.user_data?.role);
    preview_data.append("project_id", project?.project_id);
    preview_data.append("milestone_id", project?.milestone_id);
    Object.values(previewImages).map((it) => {
      preview_data.append("zip_preview", it);
    });
    if (imgFileLength > 5) {
      setImgmax(true);
      return false;
    } else if (previewImages?.length > 0) {
      // setsubmitLoader(true);
      await axios
        .post(`${BaseUrl}/client/milestone/preview-images/`, preview_data, {
          onUploadProgress: (preview_data) => {
            const previewBar = Math.round(
              (preview_data.loaded / preview_data.total) * 50
            );
            setUploaded(previewBar);
            setCombined(50 + previewBar);
          },
        })
        .then((res) => {
          setsubmitLoader(false);
          if (res?.data?.status === "Success") {
            setUploaded(null);
            setShow(false);
            setCombined(0);
            handalClose();
            setshowPreviewImageModal(false);
            setsubmitLoader(false);
            setPreviewImages([]);
          }
        });
    } else {
      setUploaded(null);
      setsubmitLoader(false);
      setError("Preview images required");
    }
  };

  const handalViewReason = (res) => {
    setIsReason(true);
    setReason(res?.decline_reason);
  };

  const handalBack = () => {
    navigate(-1);
  };

  const handalDownload = (paylaod) => {
    axios
      .post(`${BaseUrl}/professional/milestone/download/`, {
        user_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        role: "professional",
        project_id: paylaod?.project_id,
        milestone_id: paylaod?.milestone_id,
      })
      .then((result) => {
        const url = result.data?.data?.file;
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", url.split("/")[5]); // you can set the filename here
        document.body.appendChild(link);
        link.click();
      });
  };
  return (
    <div className="create-account">
      <Header2 />
      <main>
        <div className="container mb-5 bg-white" style={customStyleOne}>
          <div className="row m-0">
            <div className=" col-xxl-11 col-xl-11 col-lg-11 col-md-11 col-sm mx-auto">
              <section className="ProjectDetailsPageProjectDetailsSection">
                <div className="row">
                  <div className="col ">
                    <h3 className="theme-text-color d-flex fs-24 mb-5">
                      <button
                        className="text-decoration-none text-dark m-0 backbuttonActive"
                        onClick={handalBack}
                      >
                        <i
                          className="fa-solid fa-arrow-left-long pe-3"
                          style={{ color: "#01a78a" }}
                        ></i>
                      </button>
                      <span>Project Details</span>
                    </h3>

                    <div className="row">
                      <div className="col-xxl d-flex align-items-center my-3 align-items-center">
                        <div className="project-details">1</div>
                        <h5>Project Name:</h5>
                        <p className="m-0 ms-3">{curProject?.project_name}</p>
                      </div>
                      <div className="col-xxl d-flex align-items-center my-3 align-items-center">
                        <div className="project-details">2</div>
                        <h5>Client Name :</h5>
                        <p className="m-0 ms-3">{curProject?.client_name}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xxl d-flex align-items-center my-3 align-items-center">
                        <div className="project-details">3</div>
                        <h5>Estimated Area:</h5>
                        <p className="m-0 ms-3">{curProject?.area}</p>
                      </div>
                      <div className="col-xxl d-flex align-items-center my-3 align-items-center">
                        <div className="project-details">4</div>
                        <h5>Estimated Design Budget:</h5>
                        <p className="m-0 ms-3">$ {curProject?.project_cost}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xxl d-flex align-items-center my-3 align-items-center">
                        <div className="project-details">5</div>
                        <h5>Project Status:</h5>
                        <p className="m-0 ms-3">{curProject?.project_status}</p>
                      </div>
                      <div className="col-xxl d-flex align-items-center my-3 align-items-center">
                        <div className="project-details">6</div>
                        <h5>Estimated Deadline: </h5>
                        <p className="m-0 ms-3">{curProject?.estimated_time}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xxl d-flex align-items-center my-3 align-items-center">
                        <div className="project-details">7</div>
                        <h5>Project File: </h5>
                        <a
                          href={curProject?.attachment}
                          download={curProject?.attachment}
                          className="projectFileView"
                        >
                          View File
                        </a>
                      </div>
                      <div className="col-xxl d-flex align-items-center my-3 align-items-center">
                        <div className="project-details">8</div>
                        <h5>Work Assigned: </h5>
                        <p className="m-0 ms-3">{curProject?.work_assigned}</p>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-xxl  my-3 align-items-center">
                        <div className="d-flex align-items-center">
                          <div className="project-details">9</div>
                          <h5>Project Description: </h5>
                        </div>
                        <br />
                        <p
                          className="m-0 ms-3 "
                          style={{ whiteSpace: "pre-line" }}
                        >
                          {showText ? (
                            <div>{curProject?.description}</div>
                          ) : (
                            <div style={{ whiteSpace: "pre-line" }}>
                              {curProject?.description?.substring(0, 212)}
                            </div>
                          )}
                          {curProject?.description?.length > 100 ? (
                            !showText ? (
                              <span
                                onClick={handleShowMore}
                                style={{
                                  color: "#01a78a",
                                  marginTop: "10px",
                                  textDecoration: "underline",
                                  cursor: "pointer",
                                  // backgroundColor: "#0F9E83",
                                }}
                              >
                                Show More
                              </span>
                            ) : (
                              <span
                                onClick={handleShowLess}
                                style={{
                                  marginTop: "10px",
                                  cursor: "pointer",
                                  textDecoration: "underline",

                                  color: "#01a78a",
                                  // backgroundColor: "#0F9E83",
                                }}
                              >
                                Show Less
                              </span>
                            )
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </section>
              <section className="projectMilestoneInfo">
                <h3 className="theme-text-color fs-24 mt-5 mb-4">Milestone</h3>
                {milestone?.map((res, i) => {
                  return (
                    <>
                      <div className="milestoneBox " key={i}>
                        <div className=" d-block  ">
                          <div className="row">
                            <div className="col-4 col-xl-3">
                              <p className="headingMile">Name</p>
                              <p>{res?.milestone_name}</p>
                            </div>
                            <div className="col-4 col-xl-2 ">
                              <p className="headingMile">Date</p>
                              <div className="mileStoneDate ">
                                {res?.milestone_date}
                              </div>{" "}
                            </div>
                            <div className="col-4 col-xl-2">
                              <p className="headingMile">
                                Cost % Of Total Budget{" "}
                              </p>
                              <p>{res?.milestone_amount_percent} %</p>
                            </div>
                            <div
                              className={
                                res?.status === "decline"
                                  ? "uploadMileStoneof  uploadMileStoneofmost col-xl-5 "
                                  : "uploadMileStoneof   col-xl-5 "
                              }
                            >
                              {res?.completed_date
                                ? ""
                                : res?.status === "pending" && (
                                    <div
                                      className="uploadMileStonefo"
                                      onClick={() => {
                                        handalshow(res);
                                      }}
                                    >
                                      Upload
                                    </div>
                                  )}
                              {(res?.status === "updated" ||
                                res?.status === "downloaded" ||
                                res?.status === "uploaded") && (
                                <div className="pendingMileStone">Pending</div>
                              )}

                              {(res?.status === "accepted" ||
                                res?.status === "completed") && (
                                <div className="pendingMileStone pendingMileStone1">
                                  Completed
                                </div>
                              )}
                              {res?.professional_invoice && (
                                <a
                                  href={res?.professional_invoice}
                                  download
                                  target="_blank"
                                  className="uploadMileStonefo"
                                  style={{
                                    marginRight: "5px",
                                    textDecoration: "none",
                                  }}
                                >
                                  Invoice
                                </a>
                              )}

                              {res?.status === "decline" && (
                                <>
                                  <button
                                    title="Decline Reason"
                                    style={{
                                      backgroundColor: "transparent",
                                      border: "none",
                                    }}
                                    className="mileStoneDate decline border-none"
                                    onClick={() => handalViewReason(res)}
                                  >
                                    <AiOutlineInfoCircle
                                      color="#da220a"
                                      size={25}
                                    />
                                  </button>
                                  <div
                                    className="update uploadMileStonefo"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => handalshow(res)}
                                  >
                                    Update
                                  </div>
                                </>
                              )}

                              {res?.status !== "pending" &&
                                res?.status !== "terminated" && (
                                  <div
                                    className="uploadMileStonefo"
                                    onClick={() => {
                                      handalDownload(res);
                                    }}
                                  >
                                    Download
                                  </div>
                                )}
                              {res?.status === "terminated" && (
                                <div className="uploadMileStonefo">
                                  Terminated
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                        <div style={{ marginTop: "20px" }} className="row">
                          <div className="col-12">
                            <p className="headingMile">
                              MileStone Description:
                            </p>
                            <div className="milestonedes row">
                              <div>
                                {" "}
                                {descshowless === i + 1 ? (
                                  <p style={{ whiteSpace: "pre-line" }}>
                                    {res?.milestone_description}
                                    <span
                                      id={i + 1}
                                      style={{
                                        marginTop: "10px",
                                        cursor: "pointer",
                                        textDecoration: "underline",
                                        color: "#01a78a",
                                      }}
                                      onClick={(e) => {
                                        setdescshowless("");
                                      }}
                                    >
                                      show less
                                    </span>
                                  </p>
                                ) : (
                                  <p>
                                    {res?.milestone_description?.slice(0, 199)}{" "}
                                    {res?.milestone_description?.length <
                                    200 ? null : (
                                      <span
                                        id={i + 1}
                                        style={{
                                          marginTop: "10px",
                                          cursor: "pointer",
                                          textDecoration: "underline",
                                          color: "#01a78a",
                                        }}
                                        onClick={(e) => {
                                          setdescshowless(
                                            parseInt(e.target.id)
                                          );
                                        }}
                                      >
                                        show more
                                      </span>
                                    )}
                                  </p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  );
                })}
              </section>
            </div>
          </div>
        </div>
      </main>
      <Modal
        show={show}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        backdrop="static"
        keyboard={false}
        onHide={() => {
          handalClose();
          setUploaded(null);
          setsubmitLoader(false);
        }}
        className="modalProfessionalDashboard"
      >
        {" "}
        <button
          disabled={submitLoader ? true : false}
          className="modal-closebtn"
          onClick={() => {
            handalClose();
            setUploaded(null);
            setsubmitLoader(false);
          }}
        >
          <IoMdClose style={{ color: "#fff" }} />
        </button>
        <Modal.Body>
          <p className="text-center" style={{ fontSize: "24px" }}>
            Upload Zip & Preview image
          </p>

          <form onSubmit={handleMilestoneUpdate}>
            <div className="row">
              <div className="">
                <div className="col-12 pe-0">
                  <div className="d-flex imageDropBoxDashboardProfessional align-items-center">
                    <button className="w-100" type="button">
                      <span className="ps-2">Upload Zip</span>
                    </button>
                    {file && <p className="ps-4">{file[0]?.name}</p>}
                    <input
                      type="file"
                      style={{ cursor: "pointer" }}
                      accept=".zip,.rar,.7zip"
                      name="project"
                      onChange={handalchage}
                    />
                  </div>
                  {fileRangeError && (
                    <div className="text-danger">
                      <p>Zip size should be less then or equal to 500MB</p>
                    </div>
                  )}
                </div>
                <div className="col-12 pe-0">
                  <div className="d-flex imageDropBoxDashboardProfessional align-items-center">
                    <button className="w-100" type="button">
                      <span className="ps-2">Upload Preview Images</span>
                    </button>
                    <p className="ps-4">
                      {previewImages?.length
                        ? ` ${previewImages?.length} files selected`
                        : ""}
                    </p>
                    {imgmax ? (
                      <>
                        <span style={{ color: "red", paddingTop: "7px" }}>
                          You can select up to 5 images
                        </span>
                      </>
                    ) : null}
                    <input
                      className="custom-file-input"
                      type="file"
                      autoComplete={false}
                      style={{ cursor: "pointer" }}
                      accept="image/*"
                      multiple
                      name="previewImages"
                      onChange={(event) => {
                        setPreviewErr("");
                        setError("");
                        const selectImg = event.target.files.length;

                        setImgFileLength(selectImg);
                        if (selectImg > 5) {
                          setPreviewImages([]);
                          setImgmax(true);
                        } else {
                          setPreviewImages(event.target.files);
                          setImgmax(false);
                        }
                      }}
                    />
                  </div>
                </div>
                {uploaded > 0 && (
                  <div className="progress-file-upload mb-5">
                    <div className="progress ">
                      <div
                        className="progress-bar"
                        role="progressbar"
                        style={{ width: `${combined}%` }}
                        aria-valuenow={combined}
                        aria-valuemin="0"
                        aria-valuemax="100"
                      >{`${combined}%`}</div>
                    </div>
                  </div>
                )}
                {file && previewImages.length ? (
                  <>
                    {uploaded == 50 && (
                      <div className="my-1">
                        <p className="text-center">Keep waiting...</p>
                      </div>
                    )}
                  </>
                ) : (
                  uploaded == 100 && (
                    <div className="my-1">
                      <p className="text-center">Keep waiting...</p>
                    </div>
                  )
                )}
                <div style={{ color: "red" }}> {error}</div>
                <div className="text-center">
                  <button
                    type={submitLoader ? "button" : "submit"}
                    disabled={submitLoader ? true : false}
                    className="ModalCategorySubmit text-center"
                  >
                    {submitLoader ? <ReactLotti3 /> : "Upload"}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
      </Modal>

      {/* storage required */}

      <Modal
        show={showStorage}
        onHide={handleStoreClose}
        backdrop="static"
        centered
      >
        <Modal.Body>
          Your storage limit is full. Upgrade your plan to get more storage.
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="primary"
            onClick={handleStoreClose}
            style={{ background: "#01a78a", border: "0" }}
          >
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      {/* reason  */}
      <Modal
        centered
        size={"xl"}
        show={isReason}
        onHide={() => {
          setIsReason(false);
          setReason("");
        }}
      >
        <Modal.Header>
          <Modal.Title>
            <p>{reason}</p>
          </Modal.Title>
        </Modal.Header>
        <Modal.Footer>
          <div className="text-center">
            <Button
              className="theme-bg-color border-0"
              onClick={() => {
                setIsReason(false);
                setReason("");
              }}
            >
              ok
            </Button>
          </div>
        </Modal.Footer>
      </Modal>
      <ToastContainer
        position="top-right"
        autoClose={500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <ToastContainer />
    </div>
  );
};

export default FromProfessionalTabPane;
