import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
const Wrapper = styled.div`
  * {
    font-family: "Roboto", sans-serif;
  }
  .term_content h2 {
    font-size: 32px;
    font-weight: 700;
    color: #01a789;
  }
  a {
    text-decoration: none;
    color: #01a789;
  }
  .term_content h3 {
    font-size: 28px;
    font-weight: 700;
    color: #505050;
  }
  .term_content h4 {
    font-size: 25px;
    font-weight: 700;
    color: #505050;
  }

  .term_content h5 {
    font-size: 22px;
    font-weight: 700;
    color: black;
    padding-left: 12px;
  }

  .term_content p {
    font-size: 16px;
    font-weight: 400;
    color: #505050;
  }

  .term_content ul {
    list-style: auto;
    padding-bottom: 10px;
  }

  .term_content li {
    padding-bottom: 20px;
  }

  /* ----------- mobile ---------------- */
  @media screen and (min-width: 320px) and (max-width: 768px) {
    .term_content h2 {
      font-size: 25px;
    }
    .term_content h5 {
      font-size: 20px;
      font-weight: 100;
      color: #505050;
      padding-left: 12px;
    }
    .term_content p {
      font-size: 15px;
      font-weight: 400;
      color: #505050;
    }
  }
`;

const ContractTerms = () => {
  return (
    <>
      <div class="main">
        <header className="bg-black home-page-header">
          <div className="container">
            <nav>
              <div className="row">
                <div className="col-lg-3 col-md-4 col-7 ">
                  <Link to="/">
                    <img
                      src="./static/images/Logo8.png"
                      alt="logo"
                      style={{ height: "100px" }}
                    />
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </header>
        <Wrapper
          style={{ maxWidth: "1200px", width: "80%" }}
          className="mx-auto"
        >
          <div class="data terms_conditions py-5">
            <h4>A UNIQUE PLATFORM FOR ALL YOUR ARCHITCTURAL NEEDS</h4>
            <hr />
            <h3>RELATIONSHIPS AND RESPONSIBILITIES</h3>
            <h5>A. Client and Professional</h5>
            <p>
              1. Project Agreement the engagement, contracting and management of
              a project are between a Client and a Professional. Upon acceptance
              of a quote, the Client agrees to purchase, and the Professional
              agrees to deliver, the services and related deliverables in
              accordance with the following agreements: <br />
              (a) the agreement between Employer and Freelancer including the
              Project Proposal, Project Description, and other terms and
              conditions as communicated between Employer and Freelancer on the
              Website or otherwise <br />
              (b) these Terms of Service <br />
              (c) any other content uploaded to the Website by either party
              (collectively, the “Project Agreement”).
              <br /> You agree not to enter into any contractual provisions in
              conflict with these Terms of Service. Any provision of a Project
              Agreement in conflict with these Terms of Service is void.
              Employer is responsible for managing, inspecting, accepting and
              paying for satisfactory services and deliverables in accordance
              with the Project Agreement in a timely manner. Freelancer is
              responsible for the performance and quality of the services in
              accordance with the Project Agreement in a timely manner. Employer
              and Freelancer each covenants and agrees to act with good faith
              and fair dealing in performance of the Project Agreement. <br />
              2. The user is liable for all direct and indirect damage that he
              suffers as a result of incorrect, incomplete and / or misleading
              information that he provides when registering. The same applies if
              his information is not updated.
              <br /> 3. He agrees that all notifications related to the
              execution of this contract, as well as notifications in accordance
              with these General Conditions, can be sent to him via the contact
              e-mail address entered when creating his profile on the website.
              <br />
              4. The user is solely responsible for all content that he puts
              online on the website. The company is not obliged to check the
              content before it is placed online. <br />
              5. The user will not publish content that is offensive,
              defamatory, derogatory, slanderous, racist or xenophobic, contrary
              to morals and good customs, fake, or contrary to public order or
              the rights of third parties or the rights could damage the
              reputation and prestige of the company and, more generally,
              insofar as it would violate the law and / or regulations, in
              particular of a criminal nature.
              <br /> 6. The customer/client is solely responsible for the
              description of the order/project for which he requests a quote
              from the Professional. In the event of an error in the description
              of the order/project, the customer/client is obliged to conclude a
              corresponding additional order via the website for any additional
              services and the associated additional costs with the freelancer.
              <br />
              7. The customer/client is solely responsible for the description
              of the order/project for which he requests a quote from the
              freelancer. In the event of an error in the description of the
              order/project, the customer/client is obliged to conclude a
              corresponding additional order via the website for any additional
              services and the associated additional costs with the freelancer.
              <br />
              8. The User undertakes to access and use the Website and the
              Services only in accordance with applicable law and these General
              Conditions. 9. The User agrees that the Company may take
              cognizance of any Content published or exchanged on the Website
              for the sole purpose of verifying the User's compliance with these
              General Conditions and applicable laws. <br />
              10. Likewise, agrees that the Company may intervene to delete or
              adapt the published content if it violates the laws and
              regulations in force, as well as the obligations of users pursuant
              to these General Conditions. There is no obligation on the part of
              the company to check the published content.
            </p>
            <h5>B. Independence</h5>
            <p>
              Client and Professional each acknowledges and agrees that their
              relationship is that of independent contractors. The Professional
              shall perform services as an independent contractor and nothing in
              these Terms of Service shall be deemed to create a partnership,
              joint venture, agency, or employer-employee relationship between
              Professional and Client or between Quadra and any Client or
              Professional .You acknowledge, agree, and understand that Quadra
              does not, in any way, supervise, direct, control, or evaluate
              Professionals or their work and is not responsible for any
              Project, Project terms or Work Product.
            </p>
            <h5>C. Work Product</h5>
            <p>
              Professional is expected to deliver the Work Product (deliverable
              or outcome) of the Project Agreement using Quadra to the Client.
              Quadra can request proof of work performed at any point of time
              for verification as per Project Agreement.
            </p>
            <h5>D. Company responsibilities</h5>
            <p>
              1. The Company will do everything necessary to ensure the access
              and smooth operation of the Website and the Services 24 hours a
              day, 7 days a week. <br />
              2. The Company is not liable for any damage caused by the fact
              that access to and operation of the website and the services is
              interrupted due to the failure of third-party systems and
              facilities - in particular telecommunications providers and
              service providers - as well as force majeure or malfunctions of
              the user's devices, unless it is responsible for such damage. The
              same applies to interruptions due to necessary maintenance work to
              improve the website and the services. <br />
              3. The company provides freelancers and customers/clients with
              tools and technical means that enable them to contact each other
              via the website to conclude a service or work contract. The
              Company's responsibility is limited to providing these funds as
              described herein and bringing the freelancers and clients/clients
              together.
              <br />
              4. Company and User are independent parties, each acting on their
              own behalf and for their own account.
              <br /> 5. The company does not enter into any contract in the name
              and/or on behalf of a freelancer or a customer/client. The users
              conclude a contract with each other directly via the website.
              <br /> 6. Consequently, in particular, the company can in no case
              be considered as an employee / employer or representative of a
              user.
              <br /> 7. Since the Company is in no way involved in the contracts
              related to an assignment concluded between the Freelancers and the
              clients/clients, the latter are solely responsible for the
              generation and resolution of any disputes, claims and disputes
              that may arise at conclusion and/or performance of said contracts.
              Consequently, each User releases the Company from any liability in
              relation to direct or indirect damage, direct or indirect,
              resulting from the merging, conclusion and/or performance of such
              a contract between a Professional and a client/client.
              <br /> 8. The Company has no influence on the cancellation of
              payments or the revocation of a direct debit authorization that is
              the sole initiative of the customer/principal, nor is it liable
              for the damage that may result from such cancellation.
              <br /> 9. The company makes every effort to ensure the content and
              validity of the information and documents provided by the
              freelancers on the website. Nevertheless, the company is not
              liable for damages resulting from violations by the freelancers of
              their obligations, in particular in the context of combating
              undeclared work and compliance with transparency regulations.{" "}
              <br />
              10. The Company shall not be held responsible for any false,
              misleading or out-of-date information communicated to it by the
              Freelancer. <br />
              11. The Company reserves the right not to send communications from
              clients/clients to freelancers who do not comply with the Website
              Terms of Use.
            </p>
            <h5>6. Disputes</h5>
            <h5>Quadra's Refund Policy</h5>
            <p>
              1. Eligibility for Refund
              <br /> Quadra offers a refund option to users who meet the
              following criteria: <br />
              Users who have made payments for services or subscriptions on
              Quadra.
              <br /> Refund requests must be submitted within 2 days of the
              original transaction done for the service from Quadra. <br />
              2. Refund Processing Fee
              <br /> To cover administrative and processing costs, a refund
              processing fee of 4% will be deducted from the refund amount. This
              processing fee is non-negotiable and will be deducted from the
              original payment amount. <br />
              3. Non-Refundable Fees
              <br /> Please note that certain fees are non-refundable, and the
              processing fee will be calculated based on the remaining eligible
              amount. Non-refundable fees may include: Transaction charges
              (e.g., payment gateway fees). <br />
              4. How to Request a Refund <br />
              To request a refund, users must: <br />
              Contact Quadra's support team support@quadrafreelancers.com within
              the 2 day refund window from the day of transaction.
              <br /> Provide a detailed explanation of the reason for the refund
              request.
              <br /> Include relevant transaction details and documentation like
              transaction ID and customer ID.
              <br /> 5. Refund Approval <br />
              Quadra will review each refund request on a case-by-case basis.
              Refunds are typically processed within 30 business days from the
              date of approval. The refunded amount will be credited back to the
              original payment method used during the transaction only after the
              approval <br />
              6. Rejected Refund Requests
              <br /> Quadra reserves the right to reject refund requests that do
              not meet the eligibility criteria or that are made after 2 days of
              transaction. Users will be notified of the rejection and the
              reason for it <br />
              7. Dispute Resolution
              <br /> In the event of disputes or conflicts related to refunds,
              Quadra may, at its discretion, seek an amicable resolution between
              the parties involved. Users agree to cooperate and engage in the
              resolution process. <br />
              8. Changes to the Refund Policy
              <br /> Quadra retains the right to modify, update, or change the
              refund policy at any time. Users will be informed of any policy
              changes through the Quadra platform or official communication
              channels. <br />
              9. Contact Us <br />
              If you have any questions or concerns about Quadra's refund
              policy, please contact our support team at
              <b> support@quadrafreelancers.com</b>
            </p>
          </div>
        </Wrapper>
      </div>
    </>
  );
};

export default ContractTerms;
