import React from "react";
import blog1 from "../assets/img/blog1.png";
import { Header2 } from "../components/Header";
function BlogMain() {
  return (
    <>
      <Header2 />
      <div className="blog-wrapper container">
        <div className="blog-inner py-5">
          <div className="blog-card ">
            <div className="blog-img-wrapper">
              <img src={blog1} alt="img" className="blog-img" />
              <h1 className="blog-head mx-4">
                Designing Functional Workspaces: Office Interior Design Tips
              </h1>
            </div>
            <div className="blog-content-wrapper m-4">
              <p className="blog-content">
                Designing functional workspaces goes beyond aesthetics; it
                involves understanding the needs of your team and creating an
                environment that supports their work and well-being. By
                optimizing space, utilizing natural light, incorporating
                ergonomic furniture, and adding elements of nature, you can
                create an office that enhances productivity, fosters creativity,
                and promotes a positive company culture. A thoughtfully designed
                workspace is an investment that pays off in terms of employee
                satisfaction, retention, and overall business success. So, take
                the time to design an office that inspires, motivates, and
                empowers your team to do their best work. In today's fast-paced
                business world, the design of office spaces plays a vital role
                in the overall success of an organization. A well-designed
                office can foster creativity, productivity, and collaboration
                among employees. Gone are the days of dull, monotonous office
                setups; modern offices prioritize functionality, comfort, and
                aesthetics. In this blog, we'll explore some essential office
                interior design tips to create functional workspaces that
                inspire and elevate employee performance.
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogMain;
