import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import ClientDashboardAside from "../../ClientDashboardAside";
import { HeaderDashboard } from "../../Header";
import { useCookies } from "react-cookie";
import { Backdrop, CircularProgress } from "@mui/material";
import { Button, Container, Modal } from "react-bootstrap";
import ReactLotti3 from "../../../loader/ReactLottie3";
import { HiTrash } from "react-icons/hi";
import { BaseUrl } from "../../../BaseUrl";
import "react-toastify/dist/ReactToastify.css";
import { BiArrowBack } from "react-icons/bi";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { ToastContainer, toast } from "react-toastify";
import Dashboardside from "../../ProfessionalDashboardside";

const SubscriptionCheckout = () => {
  const stripe = useStripe();
  const elements = useElements();
  const [downloadLoader, setDownloadLoader] = useState(false);
  const [downloadLoader2, setDownloadLoader2] = useState(false);
  const [payIntId, setPayIntId] = useState();
  const [show, setShow] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [payerr, setpayerr] = useState("");

  const [cookies] = useCookies();
  const location = useLocation();
  const [card, setCard] = useState(location.state?.cards);
  const navigate = useNavigate();
  const [isRender, setIsRender] = useState(false);
  const [show2, setShow2] = useState(false);
  const [delcardId, setDelcardId] = useState();
  const [error, setError] = useState("");
  const [curCart, setCurCart] = useState("");
  // const [ project, setProject ] = useState( "" );
  const [isPayment, setIsPayment] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [payment_loader, setpayment_loader] = useState(false);
  // const [ checkout_loader, setcheckout_loader ] = useState( false );
  const [carderr, setCarderr] = useState(false);
  const [deleteLoader, setdeleteloader] = useState(false);
  const [showdelete, setShowDelete] = useState(false);

  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = (id) => {
    setDelcardId(id);
    setShowDelete(true);
  };
  const plan_payment_id = localStorage.getItem("payment_intent_id_plan");
  const [showPayModal, setshowPayModal] = useState(false);

  const handlePayClose = () => setshowPayModal(false);
  const handlePayShow = () => {
    if (curCart == "") {
      setshowPayModal(false);
      setError("Please select a card");
    } else {
      setshowPayModal(true);
    }
  };

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  });

  const handleCard = () => {
    axios
      .post(`${BaseUrl}/stripe/all_card_subscription/`, {
        professional_id: cookies?.user_data?.user_id,
        professioanl_token: cookies?.user_data?.user_token,
        plan_id: location?.state?.plan_id,
      })
      .then((result) => {
        if (
          (result?.data?.error_code === 109 &&
            result?.data?.status === "Failed") ||
          (result?.data?.status === "Failed" &&
            result?.data?.error_code === 109)
        ) {
          setIsPayment(true);
        } else {
          setError("");
          setCard(result?.data?.data?.cards);
          setIsRender(true);
        }
      });
  };
  useEffect(() => {
    handleCard();
  }, []);
  useEffect(() => {
    if (card?.length === 1) {
      setCurCart(card[0]?.id);
    }
  }, [card]);
  const payment_int_id = localStorage.getItem("payment_intent_id_plan");

  const SaveCard = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    // Api for generating client secret
    const { data } = await axios.post(
      `${BaseUrl}/stripe/professional_card_subscription/`,
      {
        professioanl_id: cookies?.user_data?.user_id,
        professioanl_token: cookies?.user_data?.user_token,
      }
    );
    const client_secret = data.client_secret_id;

    const paymentResult = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });

    if (paymentResult.error) {
      setpayerr(paymentResult.error.message);
      setPaymentLoading(false);
    } else {
      if (paymentResult.setupIntent.status === "succeeded") {
        if (
          paymentResult?.setupIntent?.payment_method &&
          paymentResult?.setupIntent?.id
        ) {
          await axios
            .post(`${BaseUrl}/stripe/pro_card_success_mail/`, {
              professioanl_id: cookies?.user_data?.user_id,
              professioanl_token: cookies?.user_data?.user_token,
              payment_method_id: paymentResult?.setupIntent?.payment_method,
            })
            .then((res) => {
              handleCard();
              setIsPayment(false);
              setPaymentLoading(false);
            })
            .catch((err) => {
              setPaymentLoading(false);
            });
        }
      }
    }
  };
  const deleteCard = () => {
    setdeleteloader(true);
    axios
      .post(`${BaseUrl}/stripe/professional_card_delete/`, {
        professioanl_id: cookies?.user_data?.user_id,
        professioanl_token: cookies?.user_data?.user_token,
        payment_method: delcardId,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          handleCard();
          setdeleteloader(false);
          setCurCart("");
          setDelcardId("");
          handleDeleteClose();
        } else if (res?.data?.status === "Failed") {
          setdeleteloader(false);
          handleDeleteClose();
        }
      });
  };

  const handalSubmit = (show) => {
    setpayment_loader(true);
    try {
      if (curCart === "") {
        setpayment_loader(false);
        throw new Error("Please select a card");
      }
      axios
        .post(`${BaseUrl}/stripe/payment_subscription/`, {
          professional_id: cookies?.user_data?.user_id,
          professioanl_token: cookies?.user_data?.user_token,
          payment_method: curCart,
          plan_id: location?.state?.plan_id,
        })
        .then((result) => {
          if (result?.data?.status === "Failed") {
            setpayment_loader(false);
            setError(result?.data?.message);
            handlePayClose();
          } else {
            window.open(result?.data?.url);
            setPayIntId(result?.data?.payment_intent_id);
            localStorage.setItem(
              "payment_intent_id_plan",
              result?.data?.payment_intent_id
            );
            setShow(show);
            handlePayClose();
            setpayment_loader(false);
          }
        });
    } catch (error) {
      setpayment_loader(false);
      handlePayClose();

      setError(error.message);
    }
  };
  const handalBack = () => {
    navigate(-1);
  };
  const showcancel = () => {};

  const hideModalCmp = () => {
    setShow(false);
    // goSubscription();
  };
  useEffect(() => {
    axios
      .post(`${BaseUrl}/stripe/subscription_detail/`, {
        professioanl_id: cookies?.user_data?.user_id,
        professioanl_token: cookies?.user_data?.user_token,
        plan_id: location?.state?.plan_id,
      })
      .then((res) => {
        if (res?.data?.status === "Success")
          localStorage.removeItem("payment_intent_id");
      });
  }, []);

  const [cartInfo, setCartInfo] = useState();

  const goSubscription = () => {
    navigate(-1);
  };
  const donwloadInvoiceSubs = () => {
    setDownloadLoader(true);
    axios
      .post(`${BaseUrl}/stripe/subscription_detail/`, {
        professioanl_id: cookies?.user_data?.user_id,
        professioanl_token: cookies?.user_data?.user_token,
        plan_id: location?.state?.plan_id,
      })
      .then((res) => {
        if (res?.data?.status === "Failed") {
          toast.error("Payment Pending");
          setDownloadLoader(false);
        } else {
          setDownloadLoader2(true);

          setDownloadLoader(false);
          // window.location.href = res?.data?.data?.invoice_url;

          const link = document.createElement("a");
          link.href = res?.data?.data?.invoice_url;
          link.target = "_blank"; // Open in a new tab
          link.rel = "noopener noreferrer"; // Security best practice for opening in a new tab

          // Append the link to the document and trigger a click event
          document.body.appendChild(link);
          link.click();
          // window.open(res?.data?.data?.invoice_url);
        }
      })
      .catch((err) => {
        setDownloadLoader(true);
      });
  };
  return (
    <>
      <div className="dashboard">
        <div className="container-fluid h-100">
          <div className="row h-100 dashboard-theme-color">
            <div className="col-xxl-2 col-md-3 col-lg-3 px-0 dashboard-theme-color">
              <Dashboardside />
            </div>
            <div className="col-xxl-10 col-md-9 col-lg-9 custom-border-radius-one dashboard-theme-skyblue px-0 dashboard-right-section">
              <HeaderDashboard />
              {!isRender ? (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={!isRender}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <>
                  <section className="checkout_data">
                    <Container>
                      <div className="checkout-listing ">
                        <div className="d-flex">
                          {" "}
                          <span
                            onClick={handalBack}
                            className="text-decoration-none text-dark m-0 h2"
                            style={{
                              cursor: "pointer",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <i
                              className="fa-solid fa-arrow-left-long "
                              style={{ color: "#01a78a" }}
                            ></i>
                          </span>
                          <h2 className="pt-3 " style={{ marginLeft: "3%" }}>
                            Checkout Payment
                          </h2>
                        </div>
                        <div className="content pt-3">
                          <div className="profile_data mb-4">
                            <div
                              className="left-image-data  "
                              style={{
                                border: "1px solid #000",
                                width: "90px",
                                height: "90px",
                                borderRadius: "50%",
                              }}
                            >
                              <img
                                src={
                                  location?.state?.professional_data
                                    ?.professional_image
                                }
                                alt="not found"
                                width="100%"
                                height="100%"
                              />
                            </div>
                            <div className="right-profile-description">
                              <h4>
                                {
                                  location?.state?.professional_data
                                    ?.professional_name
                                }
                              </h4>
                              <p>
                                {
                                  location?.state?.professional_data
                                    ?.professional_nation
                                }
                              </p>
                            </div>
                          </div>
                          <div className="amount-listing">
                            <ul className="listing">
                              <li>Amount :</li>
                            </ul>
                            <ul className="amount-list">
                              <li>
                                $ {location?.state?.professional_data?.amount}
                              </li>
                            </ul>
                          </div>
                          <div className="choose-card">
                            <h4>Choose a Card</h4>
                            <div className="card-image clearfix row">
                              {card &&
                                card.map((item, keys) => {
                                  return (
                                    <>
                                      <div
                                        className="card_div "
                                        style={{
                                          display: "contents",
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          className={
                                            item?.id === curCart
                                              ? "first-card active"
                                              : "first-card"
                                          }
                                          key={keys}
                                          onClick={() => {
                                            setCurCart(item?.id);
                                            setError("");
                                          }}
                                        >
                                          <div className="">
                                            <span
                                              onClick={() => {
                                                handleDeleteShow(item?.id);
                                              }}
                                            >
                                              <HiTrash
                                                color="white"
                                                size={25}
                                              />
                                            </span>
                                          </div>
                                          <h5>
                                            XXX XXXX XXXX {item?.card?.last4}
                                          </h5>
                                          <div className="card-details">
                                            <span>
                                              Expiry Month:{" "}
                                              {item?.card?.exp_month}
                                            </span>{" "}
                                            <span>
                                              Expiry Year:{" "}
                                              {item?.card?.exp_year}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              {error && (
                                <div className="error-box mb-3 text-danger">
                                  <span style={{ fontSize: "18px" }}>
                                    {error}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="add-card-button">
                            <button
                              className="left-button"
                              type="button"
                              onClick={() => setIsPayment(true)}
                            >
                              Add a new card
                            </button>
                          </div>

                          {card?.length !== 0 && (
                            <>
                              <div className="choose-payment-button">
                                <button
                                  disabled={payment_loader ? true : false}
                                  type="button"
                                  className="payment-btn"
                                  onClick={() => {
                                    if (!payment_loader) {
                                      // handalSubmit(true);
                                      handlePayShow();
                                    }
                                  }}
                                >
                                  Make a Payment
                                </button>
                                <button
                                  type="button"
                                  className="left-button"
                                  onClick={handalBack}
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          )}
                          {payment_loader ? (
                            <Backdrop
                              sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                              }}
                              open={payment_loader}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          ) : null}

                          {deleteLoader ? (
                            <Backdrop
                              sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                              }}
                              open={deleteLoader}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          ) : null}
                        </div>
                      </div>
                    </Container>
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Modal centered show={show} onHide={hideModalCmp} backdrop="static">
        <Modal.Body>
          <BiArrowBack
            onClick={() => {
              navigate(-1);
            }}
            size={25}
            color="#01a78a"
            className="  mx-2 "
            style={{ marginTop: "-3px", cursor: "pointer" }}
          />
          If your payment has been done then you can download invoice
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-around">
          {downloadLoader ? (
            <Button className="theme-bg-color border-0">
              <ReactLotti3 />
            </Button>
          ) : downloadLoader2 ? (
            <Button
              className="theme-bg-color border-0 px-5"
              onClick={goSubscription}
            >
              Ok
            </Button>
          ) : (
            <>
              <Button
                disabled={downloadLoader || downloadLoader2 ? true : false}
                className="theme-bg-color border-0"
                onClick={donwloadInvoiceSubs}
              >
                Download Invoice
              </Button>
            </>
          )}
        </Modal.Footer>
      </Modal>
      <Modal centered show={show2} onHide={showcancel}>
        <Modal.Body>
          <Modal.Title>New Card Added!</Modal.Title>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-start">
          <Button className="theme-bg-color border-0" onClick={showcancel}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>

      {/* new card add  */}
      <Modal
        centered
        backdrop="static"
        keyboard={false}
        show={isPayment}
        onHide={() => {
          setPaymentLoading(false);
          setIsPayment(false);
          setPaymentError("");
          setpayerr("");
          setCarderr(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
          <h4>Add Your Card details for Payment in future </h4>
        </Modal.Header>

        <Modal.Body>
          <div className="bg-white payementFormMain card-popup">
            <form onSubmit={SaveCard}>
              <div className="row m-0 pb-4 border-bottom">
                <h6 className="card-p-0">Card Number(credit/debit)</h6>

                <CardNumberElement
                  options={{
                    showIcon: true,
                  }}
                />
              </div>
              <div className="row  py-3">
                <div className="col-8">
                  <div className="row">
                    <h6>Expiry Date</h6>
                    <div className="col cardExpiry monthInput">
                      <CardExpiryElement />
                    </div>
                    <div className="col cardExpiry yearInput">
                      <CardCvcElement />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row h-100">
                    <div className="col d-flex flex-column justify-content-end"></div>
                  </div>
                </div>
              </div>
              <div style={{ color: "red" }}>{paymentError}</div>
              <div style={{ color: "red" }}>{payerr}</div>
              <div className="row">
                <button
                  type="submit"
                  disabled={isPaymentLoading}
                  className="PaymentCardSubmitButton"
                >
                  {isPaymentLoading ? <ReactLotti3 /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* delete card modal */}
      <Modal
        show={showdelete}
        onHide={handleDeleteClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure to delete this card?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button
            onClick={deleteCard}
            style={{ backgroundColor: "#00A78B", border: "none" }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Payment modal */}
      <Modal
        show={showPayModal}
        onHide={handlePayClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure to make payment?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePayClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handalSubmit(true);
            }}
            style={{ backgroundColor: "#00A78B", border: "none" }}
          >
            Sure
          </Button>
          {/* hello sachin */}
        </Modal.Footer>
      </Modal>

      {/* <Footer /> */}
    </>
  );
};

export default React.memo(SubscriptionCheckout);
