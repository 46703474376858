import React, { useState, useEffect, useContext } from "react";
import Select from "react-select";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import Footer from "../../Footer";
import ClientDashboardAside from "../../ClientDashboardAside";
import { HeaderDashboard } from "../../Header";
import { useCookies } from "react-cookie";
import { Backdrop, CircularProgress } from "@mui/material";
import { Button, Container, Modal } from "react-bootstrap";
import ReactLotti3 from "../../../loader/ReactLottie3";
import { HiTrash } from "react-icons/hi";
import { BaseUrl } from "../../../BaseUrl";
import "react-toastify/dist/ReactToastify.css";
import {
  Elements,
  CardElement,
  useStripe,
  useElements,
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import { toast, ToastContainer } from "react-toastify";

const CheckOut = () => {
  const stripe = useStripe();
  const elements = useElements();

  const [downloadLoader, setDownloadLoader] = useState(false);
  const [downloadLoader2, setDownloadLoader2] = useState(false);

  const [show, setShow] = useState(false);
  const [isPaymentLoading, setPaymentLoading] = useState(false);
  const [payerr, setpayerr] = useState("");

  const [cookies] = useCookies();
  const location = useLocation();
  const [card, setCard] = useState(location.state?.cards);
  const navigate = useNavigate();
  const [isRender, setIsRender] = useState(true);
  const [show2, setShow2] = useState(false);
  const [payIntId, setPayIntId] = useState();
  const [error, setError] = useState("");
  const [curCart, setCurCart] = useState("");
  const [project, setProject] = useState("");
  const [isPayment, setIsPayment] = useState(false);
  const [paymentError, setPaymentError] = useState("");
  const [payment_loader, setpayment_loader] = useState(false);
  const [carderr, setCarderr] = useState(false);
  const [deleteLoader, setdeleteloader] = useState(false);
  const [showdelete, setShowDelete] = useState(false);
  const [delcardId, setDelcardId] = useState();

  const handleDeleteClose = () => setShowDelete(false);
  const handleDeleteShow = (id) => {
    setDelcardId(id);
    setShowDelete(true);
  };

  const [showPayModal, setshowPayModal] = useState(false);

  const handlePayClose = () => setshowPayModal(false);
  const handlePayShow = () => {
    if (curCart == "") {
      setshowPayModal(false);
      setError("Please select a card");
    } else {
      setshowPayModal(true);
    }
  };

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  });

  const handleCard = () => {
    axios
      .post(`${BaseUrl}/client/client_checkout_details/`, {
        client_id: cookies?.user_data?.user_id,
        client_token: cookies?.user_data?.user_token,
        professional_id: location?.state?.professional_id,
        amount_paid: location?.state?.project_cost,
      })
      .then((result) => {
        if (
          (result?.data?.error_code === 109 &&
            result?.data?.status === "Failed") ||
          (result?.data?.status === "Failed" &&
            result?.data?.error_code === 109)
        ) {
          setIsPayment(true);
        } else {
          setError("");
          setCard(result?.data?.data?.cards);
        }
      });
  };
  useEffect(() => {
    handleCard();
  }, []);
  useEffect(() => {
    if (card?.length === 1) {
      setCurCart(card[0]?.id);
    }
  }, [card]);
  const payment_int_id = localStorage.getItem("payment_intent_id");
  const SaveCard = async (e) => {
    e.preventDefault();
    if (!stripe || !elements) {
      return;
    }
    setPaymentLoading(true);
    const { data } = await axios.post(`${BaseUrl}/stripe/client/card/`, {
      client_id: cookies?.user_data?.user_id,
      client_token: cookies?.user_data?.user_token,
    });
    const client_secret = data.client_secret;

    const paymentResult = await stripe.confirmCardSetup(client_secret, {
      payment_method: {
        card: elements.getElement(CardNumberElement),
      },
    });

    if (paymentResult.error) {
      setpayerr(paymentResult.error.message);
      setPaymentLoading(false);
    } else {
      if (paymentResult.setupIntent.status === "succeeded") {
        if (
          paymentResult?.setupIntent?.payment_method &&
          paymentResult?.setupIntent?.id
        ) {
          await axios
            .post(`${BaseUrl}/stripe/client/card_success_mail/`, {
              client_id: cookies?.user_data?.user_id,
              client_token: cookies?.user_data?.user_token,
              card_id: paymentResult?.setupIntent?.payment_method,
              card_setup_id: paymentResult?.setupIntent?.id,
            })
            .then((res) => {
              setIsPayment(false);
              setPaymentLoading(false);
              handleCard();
            })
            .catch((err) => {
              setPaymentLoading(false);
            });
        }
      }
    }
  };

  const deleteCard = () => {
    setdeleteloader(true);
    axios
      .post(`${BaseUrl}/stripe/client/delete/card/`, {
        client_id: cookies?.user_data?.user_id,
        client_token: cookies?.user_data?.user_token,
        card_id: delcardId,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          handleCard();
          setdeleteloader(false);
          setCurCart("");
          handleDeleteClose();
        } else if (res?.data?.status === "Failed") {
          setdeleteloader(false);
          handleDeleteClose();
        }
      });
  };

  const handalSubmit = (show) => {
    setpayment_loader(true);
    try {
      if (curCart === "") {
        setpayment_loader(false);
        throw new Error("Please select a card");
      }
      axios
        .post(`${BaseUrl}/client/purchase/buy-sell-design/`, {
          client_id: cookies?.user_data?.user_id,
          client_token: cookies?.user_data?.user_token,
          role: "client",
          professional_id: location?.state?.professional_id,
          category_id: location?.state?.category_id,
          sub_category_id: location?.state?.sub_category_id,
          project_id: location?.state?.buysell_id,
          payment_card_id: curCart,
        })
        .then((result) => {
          if (result?.data?.status === "Failed") {
            setpayment_loader(false);
            setError("Payment Failed");
            handlePayClose();
          } else {
            setProject(result?.data?.data?.project_url);
            window.open(result?.data?.vefiey);
            setPayIntId(result?.data?.payment_intent_id);
            localStorage.setItem(
              "payment_intent_id",
              result?.data?.payment_intent_id
            );
            setShow(show);
            handlePayClose();

            setpayment_loader(false);
          }
        })
        .catch((error) => {
          setpayment_loader(false);
          handlePayClose();

          setError("Payment Failed");
        });
    } catch (error) {
      setpayment_loader(false);
      handlePayClose();

      setError(error.message);
    }
  };
  const handalBack = () => {
    navigate(-1);
  };
  const showcancel = () => {
    setShow2(false);
  };
  const downloadProject = () => {
    setDownloadLoader(true);
    axios
      .post(`${BaseUrl}/client/purchase/payment_intent_retrive_buysell/`, {
        client_id: cookies?.user_data?.user_id,
        client_token: cookies?.user_data?.user_token,
        role: "client",
        payment_intent_id: payIntId,
        project_id: location?.state?.buysell_id,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          localStorage.removeItem("payment_intent_id");
          const anchor = document.createElement("a");
          anchor.style.display = "none";
          anchor.href = res?.data?.data.project_url;
          anchor.download = "filename";
          document.body.appendChild(anchor);
          anchor.click();
          document.body.removeChild(anchor);
          setDownloadLoader(false);
          setShow(false);
          navigate("/purchase-design");
        } else if (res?.data?.status === "Failed") {
          localStorage.removeItem("payment_intent_id");
          toast.error("Payment Not Completed");

          setDownloadLoader(false);
        }
      })
      .catch((err) => {
        localStorage.removeItem("payment_intent_id");
        toast.error("Payment Not Completed");
        setDownloadLoader(false);
      });
  };

  const downloadProjectLater = () => {
    setDownloadLoader2(true);

    axios
      .post(`${BaseUrl}/client/purchase/payment_intent_retrive_buysell/`, {
        client_id: cookies?.user_data?.user_id,
        client_token: cookies?.user_data?.user_token,
        role: "client",
        payment_intent_id: payIntId ? payIntId : null,
        project_id: location?.state?.buysell_id,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          navigate("/purchase-design");
          localStorage.removeItem("payment_intent_id");
        } else {
          localStorage.removeItem("payment_intent_id");
          toast.error("Payment Not Completed");
          // setShow(false);
          setDownloadLoader2(false);
        }
      })
      .catch((err) => {
        localStorage.removeItem("payment_intent_id");
        toast.error("Payment Not Completed");
        setShow(false);
        setDownloadLoader2(false);
      });
  };

  const hideModalCmp = () => {
    setShow(false);
    axios
      .post(`${BaseUrl}/client/purchase/payment_intent_retrive_buysell/`, {
        client_id: cookies?.user_data?.user_id,
        client_token: cookies?.user_data?.user_token,
        role: "client",
        payment_intent_id: payment_int_id ? payment_int_id : null,
        project_id: location?.state?.buysell_id,
      })
      .then((res) => {
        setShow(false);
        if (res?.data?.status === "Success")
          localStorage.removeItem("payment_intent_id");
        else {
          localStorage.removeItem("payment_intent_id");
        }
      })
      .catch((err) => {
        setShow(false);
      });
  };
  useEffect(() => {
    axios
      .post(`${BaseUrl}/client/purchase/payment_intent_retrive_buysell/`, {
        client_id: cookies?.user_data?.user_id,
        client_token: cookies?.user_data?.user_token,
        role: "client",
        payment_intent_id: payment_int_id ? payment_int_id : null,
        project_id: location?.state?.buysell_id,
      })
      .then((res) => {
        if (res?.data?.status === "Success")
          localStorage.removeItem("payment_intent_id");
      });
  }, []);

  // payment card

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid h-100">
          <div className="row h-100 dashboard-theme-color">
            <div className="col-xxl-2 col-md-3 col-lg-3 px-0 dashboard-theme-color">
              <ClientDashboardAside />
            </div>
            <div className="col-xxl-10 col-md-9 col-lg-9 custom-border-radius-one dashboard-theme-skyblue px-0 dashboard-right-section">
              <HeaderDashboard />
              {!isRender ? (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={!isRender}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <>
                  <section className="checkout_data">
                    <Container>
                      <div className="checkout-listing ">
                        <div className="d-flex">
                          {" "}
                          <span
                            onClick={handalBack}
                            className="text-decoration-none text-dark m-0 h2"
                            style={{
                              cursor: "pointer",
                              display: "grid",
                              placeItems: "center",
                            }}
                          >
                            <i
                              className="fa-solid fa-arrow-left-long "
                              style={{ color: "#01a78a" }}
                            ></i>
                          </span>
                          <h2 className="pt-3 " style={{ marginLeft: "3%" }}>
                            Checkout Payment
                          </h2>
                        </div>
                        <div className="content pt-3">
                          <div className="profile_data mb-4">
                            <div
                              className="left-image-data  "
                              style={{
                                border: "1px solid #000",
                                width: "90px",
                                height: "90px",
                                borderRadius: "50%",
                              }}
                            >
                              <img
                                src={location?.state?.professional_image}
                                alt=""
                                width="100%"
                                height="100%"
                              />
                            </div>
                            <div className="right-profile-description">
                              <h4>{location?.state?.professional_name}</h4>
                              <p>{location?.state?.professional_nation}</p>
                            </div>
                          </div>
                          <div className="amount-listing">
                            <ul className="listing">
                              <li>Amount</li>
                              <li>Service Charge 9%</li>
                              <li>Tax</li>
                              <li>Total Amount</li>
                            </ul>
                            <ul className="amount-list">
                              <li>$ {location?.state?.amount}</li>
                              <li>$ {location?.state?.charge}</li>
                              <li>
                                ${" "}
                                {location?.state?.gst
                                  ? location?.state?.gst
                                  : "0"}
                              </li>
                              <li>$ {location?.state?.total_amount}</li>
                            </ul>
                          </div>
                          <div className="choose-card">
                            <h4>Choose a Card</h4>
                            <div className="card-image clearfix row">
                              {card &&
                                card.map((item, keys) => {
                                  return (
                                    <>
                                      <div
                                        className="card_div "
                                        style={{
                                          display: "contents",
                                          position: "relative",
                                        }}
                                      >
                                        <div
                                          className={
                                            item?.id === curCart
                                              ? "first-card active"
                                              : "first-card"
                                          }
                                          key={keys}
                                          onClick={() => {
                                            setCurCart(item?.id);
                                            setError("");
                                          }}
                                        >
                                          <div className="">
                                            <span
                                              onClick={() => {
                                                handleDeleteShow(item?.id);
                                              }}
                                            >
                                              <HiTrash
                                                color="white"
                                                size={25}
                                              />
                                            </span>
                                          </div>
                                          <h5>
                                            XXX XXXX XXXX {item?.card?.last4}
                                          </h5>
                                          <div className="card-details">
                                            <span>
                                              Expiry Month:{" "}
                                              {item?.card?.exp_month}
                                            </span>{" "}
                                            <span>
                                              Expiry Year:{" "}
                                              {item?.card?.exp_year}
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  );
                                })}
                              {error && (
                                <div className="error-box mb-3 text-danger">
                                  <span style={{ fontSize: "18px" }}>
                                    {error}
                                  </span>
                                </div>
                              )}
                            </div>
                          </div>

                          <div className="add-card-button">
                            <button
                              className="left-button"
                              type="button"
                              onClick={() => setIsPayment(true)}
                            >
                              Add a new card
                            </button>
                          </div>

                          {card.length !== 0 && (
                            <>
                              <div className="choose-payment-button">
                                <button
                                  disabled={payment_loader ? true : false}
                                  type="button"
                                  className="payment-btn"
                                  onClick={() => {
                                    if (!payment_loader) {
                                      // handalSubmit(true);
                                      handlePayShow();
                                    }
                                  }}
                                >
                                  Make a Payment
                                </button>
                                <button
                                  type="button"
                                  className="left-button"
                                  onClick={handalBack}
                                >
                                  Cancel
                                </button>
                              </div>
                            </>
                          )}
                          {payment_loader ? (
                            <Backdrop
                              sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                              }}
                              open={payment_loader}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          ) : null}

                          {deleteLoader ? (
                            <Backdrop
                              sx={{
                                color: "#fff",
                                zIndex: (theme) => theme.zIndex.drawer + 1,
                              }}
                              open={deleteLoader}
                            >
                              <CircularProgress color="inherit" />
                            </Backdrop>
                          ) : null}
                        </div>
                      </div>
                    </Container>
                  </section>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
      <Modal centered show={show} onHide={hideModalCmp} backdrop="static">
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <Modal.Title>
            <h4 className="mb-3 text-dark" style={{ fontSize: "17px" }}>
              Please Do Not Refresh Page
            </h4>
            <p> You can download your file if payment is successfull </p>
          </Modal.Title>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-around">
          <Button
            disabled={downloadLoader || downloadLoader2 ? true : false}
            className="theme-bg-color border-0"
            onClick={downloadProject}
          >
            {downloadLoader ? <ReactLotti3 /> : "Download Design"}
          </Button>
          <Button
            disabled={downloadLoader || downloadLoader2 ? true : false}
            className="theme-bg-color border-0 px-4"
            onClick={downloadProjectLater}
          >
            {downloadLoader2 ? <ReactLotti3 /> : "Later"}
          </Button>
        </Modal.Footer>
      </Modal>
      <Modal centered show={show2} onHide={showcancel}>
        <Modal.Body>
          <Modal.Title>New Card Added!</Modal.Title>
        </Modal.Body>

        <Modal.Footer className="d-flex justify-content-start">
          <Button className="theme-bg-color border-0" onClick={showcancel}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
      <ToastContainer />
      {/* new card add  */}
      <Modal
        centered
        backdrop="static"
        keyboard={false}
        show={isPayment}
        onHide={() => {
          setIsPayment(false);
          setPaymentError("");
          setpayerr("");
          setCarderr(false);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title></Modal.Title>
          <h4>Add Your Card details for Payment in future </h4>
        </Modal.Header>

        <Modal.Body>
          <div className="bg-white payementFormMain card-popup">
            <form onSubmit={SaveCard}>
              <div className="row m-0 pb-4 border-bottom">
                <h6 className="card-p-0">Card Number(credit/debit)</h6>

                <CardNumberElement
                  options={{
                    showIcon: true,
                  }}
                />
              </div>
              <div className="row  py-3">
                <div className="col-8">
                  <div className="row">
                    <h6>Expiry Date</h6>
                    <div className="col cardExpiry monthInput">
                      <CardExpiryElement />
                    </div>
                    <div className="col cardExpiry yearInput">
                      <CardCvcElement />
                    </div>
                  </div>
                </div>
                <div className="col-4">
                  <div className="row h-100">
                    <div className="col d-flex flex-column justify-content-end"></div>
                  </div>
                </div>
              </div>
              <div style={{ color: "red" }}>{paymentError}</div>
              <div style={{ color: "red" }}>{payerr}</div>
              <div className="row">
                <button
                  type="submit"
                  disabled={isPaymentLoading}
                  className="PaymentCardSubmitButton"
                >
                  {isPaymentLoading ? <ReactLotti3 /> : "Save"}
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
      {/* delete card modal */}
      <Modal
        show={showdelete}
        onHide={handleDeleteClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure to delete this card?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleDeleteClose}>
            Cancel
          </Button>
          <Button
            onClick={deleteCard}
            style={{ backgroundColor: "#00A78B", border: "none" }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Payment modal */}
      <Modal
        show={showPayModal}
        onHide={handlePayClose}
        backdrop="static"
        keyboard={false}
        centered
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>Are you sure to make payment?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handlePayClose}>
            Cancel
          </Button>
          <Button
            onClick={() => {
              handalSubmit(true);
            }}
            style={{ backgroundColor: "#00A78B", border: "none" }}
          >
            Sure
          </Button>
        </Modal.Footer>
      </Modal>

      {/* <Footer /> */}
    </>
  );
};

export default React.memo(CheckOut);
