import React, { useState, useEffect, useContext } from "react";
import axios from "axios";
import Global from "../../../context/Global";
import { useNavigate } from "react-router-dom";
import { CiLocationOn } from "react-icons/ci";
import Pagination from "react-bootstrap/Pagination";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ClientDashboardAside from "../../ClientDashboardAside";
import { HeaderDashboard } from "../../Header";
import { useCookies } from "react-cookie";
import { BsSearch } from "react-icons/bs";
import { Backdrop, CircularProgress } from "@mui/material";
import { BaseUrl } from "../../../BaseUrl";
import Dashboardside from "../../ProfessionalDashboardside";

const Contract = () => {
  const navigate = useNavigate();
  const [cookies] = useCookies();
  const contextData = useContext(Global);
  const [onGoingProject, setOnGoingProject] = useState([]);
  const [noResult, setNoResult] = useState(false);
  const [contractStatus, setContractStatus] = useState("on_going");
  const [onGoingProjectPageId, setOnGoingProjectPageId] = useState({
    page: 1,
    page_size: 10,
  });

  useEffect(() => {
    if (!cookies?.user_data) {
      navigate("/");
    }
  });

  const [isRender, setIsReander] = useState(false);
  useEffect(() => {
    axios
      .post(`${BaseUrl}/client/contract_list/`, {
        user_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
        contract_type: contractStatus,
        ...onGoingProjectPageId,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          setIsReander(true);
          setOnGoingProject(res?.data?.data);
        } else {
          setIsReander(true);
          setNoResult(true);
        }
      })

      .catch((err) => {});
  }, [onGoingProjectPageId]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [onGoingProjectPageId]);

  const onGoingProjectArray = [];
  for (
    let i = 0;
    i < onGoingProject?.total_data / onGoingProjectPageId?.page_size;
    i++
  ) {
    onGoingProjectArray.push(i + 1);
  }

  //
  const ongoing = () => {
    axios
      .post(`${BaseUrl}/client/contract_list/`, {
        user_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
        contract_type: "on_going",
        ...onGoingProjectPageId,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          setIsReander(true);
          setNoResult(false);
          setOnGoingProject(res?.data?.data);
        } else {
          setIsReander(true);
          setNoResult(true);
        }
      })

      .catch((err) => {});
  };
  const history = () => {
    axios
      .post(`${BaseUrl}/client/contract_list/`, {
        user_id: cookies?.user_data?.user_id,
        user_token: cookies?.user_data?.user_token,
        role: cookies?.user_data?.role,
        contract_type: "history",
        ...onGoingProjectPageId,
      })
      .then((res) => {
        if (res?.data?.status === "Success") {
          setNoResult(false);
          setIsReander(true);
          setOnGoingProject(res?.data?.data);
        } else {
          setIsReander(true);
          setNoResult(true);
        }
      })

      .catch((err) => {});
  };

  return (
    <>
      <div className="dashboard">
        <div className="container-fluid h-100">
          <div className="row h-100 dashboard-theme-color">
            <div className="col-xxl-2 col-md-3 col-lg-3 px-0 dashboard-theme-color">
              {cookies?.user_data?.role === "client" ? (
                <ClientDashboardAside />
              ) : (
                <Dashboardside />
              )}
            </div>
            <div className="col-xxl-10 col-md-9 col-lg-9 custom-border-radius-one dashboard-theme-skyblue px-0 dashboard-right-section">
              <HeaderDashboard />
              {!isRender ? (
                <Backdrop
                  sx={{
                    color: "#fff",
                    zIndex: (theme) => theme.zIndex.drawer + 1,
                  }}
                  open={!isRender}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
              ) : (
                <main className="dashboard-main">
                  <div
                    id="myactivity"
                    className="container-fluid  myProjectTable"
                  >
                    <div
                      className="ps-5 d-sm-flex justify-content-between align-items-center"
                      style={{ paddingRight: "2%" }}
                    >
                      <h2 className="">Contracts</h2>
                      <div className="">
                        <button
                          onClick={() => {
                            if (onGoingProjectPageId.page === 1) {
                              ongoing();
                            } else {
                              setOnGoingProjectPageId((prev) => ({
                                ...prev,
                                page: 1,
                              }));
                              setContractStatus("on_going");
                            }
                          }}
                          className=" btn text-white me-2 mb-lg-2 mb-xxl-0"
                          style={{ backgroundColor: "#00A78B" }}
                        >
                          On Going Contracts
                        </button>
                        <button
                          onClick={() => {
                            if (onGoingProjectPageId.page === 1) {
                              history();
                            } else {
                              setOnGoingProjectPageId((prev) => ({
                                ...prev,
                                page: 1,
                              }));
                              setContractStatus("history");
                            }
                          }}
                          className=" btn text-white me-2 mb-lg-2 mb-xxl-0"
                          style={{ backgroundColor: "#00A78B" }}
                        >
                          History
                        </button>
                      </div>
                    </div>
                    <div className="m-lg-5 shadow">
                      {noResult ? (
                        <div
                          style={{
                            minHeight: "600px",
                            display: "grid",
                            placeItems: "center",
                          }}
                        >
                          <span className="h4">No Result Found</span>
                        </div>
                      ) : onGoingProject?.final_data?.length ? (
                        onGoingProject.final_data.map((res, index) => (
                          <div
                            className="row MyProjectDisplayRow pointer justify-content-around"
                            key={index}
                            onClick={() => {
                              navigate(`/contract-details/${res?.id}`, {
                                state: {
                                  project_id: res?.project_id,
                                },
                              });
                            }}
                          >
                            <div className="col-lg-3 col-md-6 d-flex align-items-center">
                              {cookies?.user_data?.role === "professional" ? (
                                <img
                                  src={res?.client_image}
                                  className="img-fluid rounded-circle"
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    cursor: "pointer",
                                  }}
                                  alt={res?.client_name}
                                />
                              ) : (
                                <img
                                  src={res?.professional_image}
                                  className="img-fluid rounded-circle"
                                  style={{
                                    width: "70px",
                                    height: "70px",
                                    cursor: "pointer",
                                  }}
                                  alt={res?.professional_name}
                                />
                              )}
                              <div className="ps-3">
                                <h4 className="">
                                  {cookies?.user_data?.role === "professional"
                                    ? res?.client_name
                                    : res?.professional_name}
                                </h4>
                                <h6>{res?.location}</h6>
                              </div>
                            </div>
                            <div className="col-lg-3 col-md-6 d-flex  align-items-center">
                              <div>
                                <h5>Project Name</h5>
                                <h4
                                  style={{ textTransform: "capitalize" }}
                                  className="underline_hover"
                                >
                                  {res?.project_name}
                                </h4>
                              </div>
                            </div>

                            <div className="col-lg-3 col-md-6 d-flex align-items-center">
                              <div>
                                <h5>Project Price</h5>
                                <h4>${res?.project_price}</h4>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : null}
                    </div>
                    {onGoingProject?.final_data?.length ? (
                      onGoingProject?.total_data > 10 ? (
                        <Pagination className="ps-5 paginationBoxProfessionalDashboard">
                          <Pagination.First
                            onClick={() => {
                              setOnGoingProjectPageId({
                                page: 1,
                                page_size: 10,
                              });
                            }}
                          />
                          <Pagination.Prev
                            onClick={() => {
                              setOnGoingProjectPageId((prev) => ({
                                ...prev,
                                page:
                                  onGoingProjectPageId?.page !== 1
                                    ? onGoingProjectPageId?.page - 1
                                    : 1,
                              }));
                            }}
                          />
                          {onGoingProjectArray?.map((res, key) => (
                            <Pagination.Item
                              key={key}
                              active={onGoingProjectPageId?.page === res}
                              onClick={() => {
                                setOnGoingProjectPageId((prev) => ({
                                  ...prev,
                                  page: res,
                                }));
                              }}
                            >
                              {res}
                            </Pagination.Item>
                          ))}
                          <Pagination.Next
                            onClick={() => {
                              setOnGoingProjectPageId((prev) => ({
                                ...prev,
                                page:
                                  onGoingProjectArray?.length !==
                                  onGoingProjectPageId?.page
                                    ? onGoingProjectPageId?.page + 1
                                    : onGoingProjectPageId?.page,
                              }));
                            }}
                          />
                          <Pagination.Last
                            onClick={() => {
                              setOnGoingProjectPageId((prev) => ({
                                ...prev,
                                page: onGoingProjectArray?.length,
                              }));
                            }}
                          />
                        </Pagination>
                      ) : null
                    ) : (
                      ""
                    )}
                  </div>
                  <ToastContainer
                    position="top-center"
                    autoClose={3000}
                    hideProgressBar={true}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                    toastStyle={{ backgroundColor: "red", color: "white" }}
                  />
                </main>
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default React.memo(Contract);
